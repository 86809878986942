import React, { Component } from 'react';
import { Col, Form, Row } from 'react-bootstrap-v5';
import ReactModal from 'react-modal';
import IconButton from "../../../components/common/buttons/icon-button/IconButton";
import styles from '../../../styles/ModalWindow.module.scss';
import { ICopyContestModalProps } from "./ICopyContestModalProps";
import { ICopyContestModalState } from "./ICopyContestModalState";
import Api from "../../../api/Api";
import Validations from "../../../helpers/validation-helper/Validations";
import { ICopyContestRequest } from "../../../api/api-interfaces/entry/ICopyContestRequest";
import ValidationErrors from "../../../helpers/validation-helper/ValidationErrors";
import ValidationSummary from "../../../components/common/validation-summary/ValidationSummary";
import LoadingBar from "../../../components/common/loading-bar/LoadingBar";

class CopyContestModal extends Component<ICopyContestModalProps, ICopyContestModalState> {

  private static dialogIndex = 100;

  constructor(props: ICopyContestModalProps) {
    super(props);

    this.state = {
      dialogId: 'copy_contest_dialog_' + (CopyContestModal.dialogIndex++),

      shouldCopyContestEntryFields: true,
      shouldCopyEntrySubmissionNotificationRecipients: false,
      shouldCopyJudges: true,
      isNewContestDeactivated: true,

      isLoading: false,
      validationErrors: {}
    };
  }

  render() {
    return (
      <ReactModal isOpen={this.props.showDialog}
                  id={this.state.dialogId}
                  shouldCloseOnOverlayClick={false}
                  shouldCloseOnEsc={false}
                  className={`${styles.content} shadow p-4`}
                  overlayClassName={`${styles.overlay}`}
                  appElement={document.getElementById('root') as HTMLElement}
      >
        {
          this.state.validationErrors &&
          <Row>
            <Col md={{span: 8, offset: 2}}>
              <ValidationSummary errors={this.state.validationErrors}/>
            </Col>
          </Row>
        }

        {
          this.state.isLoading ? <LoadingBar styles={{marginTop: '10vh', marginBottom: '10vh'}}/> :
            <>
              <h2>Copy contest</h2>

              <p>Are you sure you want to copy <b className={"text-break"}> {this.props.contestNameToCopy}</b>?</p>

              <p>Please select what features of contest should be copied from old one</p>

              <Form onSubmit={this.onSubmitClick.bind(this)}>
                <Form.Check type='checkbox' id="CopyContestEntryFields"
                            name="CopyContestEntryFields"
                            checked={this.state.shouldCopyContestEntryFields}
                            onChange={this.onCopyContestEntryFieldsChange.bind(this)}
                            label={<>Copy contest entry fields</>}
                />

                <Form.Check type='checkbox' id="CopyEntrySubmissionsNotificationList"
                            name="CopyEntrySubmissionsNotificationList"
                            checked={this.state.shouldCopyEntrySubmissionNotificationRecipients}
                            onChange={this.onCopyEntrySubmissionsNotificationListChange.bind(this)}
                            label={<>Copy entry submissions notification list</>}
                />

                <Form.Check type='checkbox' id="CopyJudgesList"
                            name="CopyJudgesList"
                            checked={this.state.shouldCopyJudges}
                            onChange={this.onCopyJudgesListChange.bind(this)}
                            label={<>Copy judges list</>}
                />

                <Form.Check type='checkbox' id="MakeNewContestDeactivated"
                            name="MakeNewContestDeactivated"
                            checked={this.state.isNewContestDeactivated}
                            onChange={this.onMakeNewContestDeactivatedChange.bind(this)}
                            label={<>Make new contest deactivated</>}
                />

                <div className="d-flex justify-content-end">
                  <IconButton buttonType={"submit"} variant="outline-primary" title="Copy"
                              styles={{marginRight: '1em'}} disabled={this.props.isLoading}/>

                  <IconButton variant="outline-secondary" title="Cancel"
                              onClick={this.onCancelClick.bind(this)}/>
                </div>
              </Form>
            </>
        }
      </ReactModal>
    );
  }

  onCopyContestEntryFieldsChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({shouldCopyContestEntryFields: e.target.checked})
  }

  onCopyEntrySubmissionsNotificationListChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({shouldCopyEntrySubmissionNotificationRecipients: e.target.checked})
  }

  onCopyJudgesListChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({shouldCopyJudges: e.target.checked})

  }

  onMakeNewContestDeactivatedChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({isNewContestDeactivated: e.target.checked})
  }

  private async onSubmitClick(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    this.setState({isLoading: true, validationErrors: {}});

    try {
      let contestId = this.props.contestIdToCopy ?? 0;

      let request: ICopyContestRequest = {
        contestId: contestId,
        shouldCopyContestEntryFields: this.state.shouldCopyContestEntryFields,
        shouldCopyEntrySubmissionNotificationRecipients: this.state.shouldCopyEntrySubmissionNotificationRecipients,
        shouldCopyJudges: this.state.shouldCopyJudges,
        isNewContestDeactivated: this.state.isNewContestDeactivated
      }

      let response = await Api.copyContestFromEntryList(request);

      if (this.props.onConfirm) {
        this.props.onConfirm(response.newContestId);
      }
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t copy contest on the server', err)
      );
    }
  }

  private onCancelClick() {
    this.setValidationErrors({});
    this.setState({
      shouldCopyContestEntryFields: true,
      shouldCopyEntrySubmissionNotificationRecipients: false,
      shouldCopyJudges: true,
      isNewContestDeactivated: true
    })

    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }
}

export default CopyContestModal;