import React from 'react';
import { Col, Row } from "react-bootstrap-v5";
import { Link } from "react-router-dom";
import IconButton from "../../components/common/buttons/icon-button/IconButton";
import { VerticalSpaceSize } from "../../components/common/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../components/common/vertical-space/VerticalSpace";
import RoutingConstants from "../../routes/RoutingConstants";

class NoPermitted extends React.Component {
  render() {
    return (
      <>
        <VerticalSpace size={VerticalSpaceSize.large}/>
          <div className="text-center mt-5">
            <Row>
              <Col md={{span: 6, offset: 3}}>
                <h1 className={"headline"}>403 - Forbidden</h1>

                <p>You don’t have permission to access this page</p>
                <br/>
                <Link to={RoutingConstants.LOGIN}>
                  <IconButton variant="outline-primary" title="Back to site"/>
                </Link>
              </Col>
            </Row>
          </div>
      </>
    );
  }
}

export default NoPermitted;