import * as Cookies from 'es-cookie';
import ISimpleEvent from '../events/ISimpleEvent';
import SimpleEvent from '../events/SimpleEvent';

export default class AuthHelper {
  private static readonly AuthCookieName: string = 'VotingAppAuth';
  private static readonly CurrentUserFullName: string = 'VotingAppCurrentUserFullName';
  private static readonly EmailCookieName: string = 'VotingAppEmail';
  private static readonly IsCurrentUserAdmin: string = 'VotingAppIsCurrentUserAdmin';
  private static readonly JudgeContestIds: string = 'VotingAppJudgeContestIds';
  private static readonly onChangeEventDispatcher = new SimpleEvent<void>();

  public static setAccessToken(token: string, expirationSeconds: Date): void {
    Cookies.set(AuthHelper.AuthCookieName, token, {expires: expirationSeconds});

    this.onChangeEventDispatcher.trigger();
  }

  public static setUserFullName(currentUserFullName: string, expirationSeconds: Date): void {
    Cookies.set(AuthHelper.CurrentUserFullName, currentUserFullName, {expires: expirationSeconds});
  }

  public static setEmail(email: string, expirationSeconds: Date): void {
    Cookies.set(AuthHelper.EmailCookieName, email, {expires: expirationSeconds});
  }

  public static setJudgeContestIds(judgeContestIds: string, expirationSeconds: Date): void {
    Cookies.set(AuthHelper.JudgeContestIds, judgeContestIds, {expires: expirationSeconds});
  }

  public static setIsCurrentUserAdmin(isCurrentUserAdmin: boolean, expirationSeconds: Date): void {
    Cookies.set(AuthHelper.IsCurrentUserAdmin, isCurrentUserAdmin ? 'Yes' : 'No', {expires: expirationSeconds});
  }

  public static getAccessToken(): string | undefined {
    return Cookies.get(AuthHelper.AuthCookieName);
  }

  public static isTokenSet(): boolean {
    let token = AuthHelper.getAccessToken();

    return token !== undefined && token !== null && token !== '';
  }

  public static getEmail(): string | undefined {
    return Cookies.get(AuthHelper.EmailCookieName);
  }

  public static getUserFullName(): string {
    return Cookies.get(AuthHelper.CurrentUserFullName) ?? '';
  }

  public static getIsCurrentUserAdmin(): string | undefined {
    return Cookies.get(AuthHelper.IsCurrentUserAdmin);
  }

  public static isCurrentUserAdmin(): boolean {
    let isCurrentUserAdmin = AuthHelper.getIsCurrentUserAdmin();

    return !!(isCurrentUserAdmin && isCurrentUserAdmin === 'Yes');
  }

  public static getJudgeContestIds(): string | undefined {
    return Cookies.get(AuthHelper.JudgeContestIds);
  }

  public static isCurrentUserJudge(): boolean {
    let judgeContestIds: string | undefined = AuthHelper.getJudgeContestIds();
    let parsedJudgeContestIds: number[] = JSON.parse(judgeContestIds ? judgeContestIds : '[]');

    return parsedJudgeContestIds && parsedJudgeContestIds.length > 0;
  }

  public static removeSession() {
    Cookies.remove(this.AuthCookieName);
    Cookies.remove(this.CurrentUserFullName);
    Cookies.remove(this.IsCurrentUserAdmin);
    Cookies.remove(this.JudgeContestIds);

    let today = new Date();
    let yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    AuthHelper.setAccessToken('', yesterday);
  }

  public static get onChange(): ISimpleEvent<void> {
    return this.onChangeEventDispatcher;
  }
}