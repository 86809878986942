export default class RoutingConstants {
  public static HOME: string = '/';
  public static LOGIN: string = '/login';

  public static CONTESTS: string = '/contests/:page/:contestId';
  public static CREATE_CONTEST: string = '/contest-create';
  public static EDIT_CONTEST: string = '/contest-edit/:contestId';

  public static CONTEST_ENTRIES: string = '/contest-entries/:contestId';
  public static CREATE_ENTRY: string = '/contest/:contestId/entry-create';
  public static EDIT_ENTRY: string = '/contest/:contestId/entry-edit/:entryId';
  public static ENTRY_DETAILS: string = '/contest/:contestId/entry-details/:entryId';

  public static ENTRY_VOTE_LIST: string = '/entry-vote-list/:entryId';

  public static USERS: string = '/users/:page/:userId';
  public static CREATE_USER: string = '/user-create';
  public static UPDATE_USER: string = '/user-update/:userId';

  public static JUDGES: string = '/judges/:contestId';

  public static NOTIFICATION_LIST: string = '/notification-list/:contestId';

  public static NO_PERMITTED: string = '/no-permitted';

  public static UNSUBSCRIBE: string = '/unsubscribe/:subscriptionId';

  public static buildContestUrl(page: number, contestId: number, sorting?: string): string {
    if (sorting) {
      return `/contests/${page}/${contestId}?sorting=${sorting}`;
    } else {
      return `/contests/${page}/${contestId}`;
    }
  };

  public static buildCreateContestUrl(page: number, sorting: string): string {
    return `/contest-create?page=${page}&sorting=${sorting}`;
  };

  public static buildEditContestUrl(
    contestId: number,
    page: number,
    sorting: string,
    redirectUrl?: string,
    shouldShowContestIsCopiedToastMessage?: 'yes' | 'no',
  ): string {
    let appendRedirectUrlIfExist = redirectUrl ? `&redirectUrl=${redirectUrl}` : '';

    let appendShouldShowContestIsCopiedToastMessageIfExist = shouldShowContestIsCopiedToastMessage
      ? `&shouldShowContestIsCopiedToastMessage=${shouldShowContestIsCopiedToastMessage}`
      : '';

    return `/contest-edit/${contestId}?page=${page}&sorting=${sorting}`
      + appendRedirectUrlIfExist
      + appendShouldShowContestIsCopiedToastMessageIfExist;
  };

  public static buildContestDetailsUrl(contestId: number): string {
    return `/contest-entries/${contestId}`;
  };

  public static buildEntryDetailsUrl(contestId: number, entryId: number): string {
    return `/contest/${contestId}/entry-details/${entryId}`;
  };

  public static buildCreateEntryUrl(contestId: number): string {
    return `/contest/${contestId}/entry-create`;
  };

  public static buildEditEntryUrl(contestId: number, entryId: number): string {
    return `/contest/${contestId}/entry-edit/${entryId}`;
  };

  public static buildEntryVoteListUrl(entryId: number): string {
    return `/entry-vote-list/${entryId}`;
  };

  public static buildUsersUrl(page: number, userId: number, sorting?: string): string {
    if (sorting) {
      return `/users/${page}/${userId}?sorting=${sorting}`;
    } else {
      return `/users/${page}/${userId}`;
    }
  };

  public static buildCreateUserUrl(page: number, sorting: string): string {
    return `/user-create?page=${page}&sorting=${sorting}`;
  };

  public static buildUpdateUserUrl(userId: number, page: number, sorting: string, redirectUrl?: string): string {
    if (redirectUrl) {
      return `/user-update/${userId}?page=${page}&redirectUrl=${redirectUrl}`;
    } else {
      return `/user-update/${userId}?page=${page}&sorting=${sorting}`;
    }
  };

  public static buildJudgeListUrl(contestId: number): string {
    return `/judges/${contestId}`;
  };

  public static buildContestNotificationListUrl(contestId: number): string {
    return `/notification-list/${contestId}`;
  };
}
