import {
  faArrowDown,
  faArrowUp,
  faCheck,
  faPencilAlt,
  faPlus,
  faToggleOff,
  faToggleOn,
  faTrashAlt,
  faArrowRight,
  faArrowLeft
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap-v5';
import { IIconButtonProps } from './IIconButtonProps';

export default class IconButton extends Component<IIconButtonProps> {

  constructor(props: Readonly<IIconButtonProps>) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  render() {
    return (
      <>
        <Button type={this.props.buttonType} variant={this.props.variant} onClick={this.onClickHandler}
                style={this.props.styles} className={this.props.className} disabled={this.props.disabled}
                size={this.props.size} title={this.props.tooltip}>
          {
            (this.props.title !== undefined && this.props.title !== '' && this.props.title !== null)
              ?
              <>
                <span className='d-inline-block pr-2'>{this.renderIcon()}</span>
                <span className="align-baseline">
                  {
                    this.props.isLoading &&
                    <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"/>
                  }

                  {this.props.title}
                </span>
              </>
              :
              <>
                <span className='d-inline-block'>{this.renderIcon()}</span>
              </>
          }
        </Button>
      </>
    );
  }

  renderIcon() {
    switch (this.props.iconType) {
      case 'submit':
        return <FontAwesomeIcon icon={faCheck}/>;
      case 'add':
        return <FontAwesomeIcon icon={faPlus}/>;
      case 'edit':
        return <FontAwesomeIcon icon={faPencilAlt}/>;
      case 'delete':
        return <FontAwesomeIcon icon={faTrashAlt}/>;
      case 'active':
        return <FontAwesomeIcon icon={faToggleOn}/>;
      case 'notActive':
        return <FontAwesomeIcon icon={faToggleOff}/>;
      case 'up':
        return <FontAwesomeIcon icon={faArrowUp}/>;
      case 'down':
        return <FontAwesomeIcon icon={faArrowDown}/>;
      case 'check':
        return <FontAwesomeIcon icon={faCheck}/>;
      case 'arrowRight':
        return <FontAwesomeIcon icon={faArrowRight}/>;
      case 'arrowLeft':
        return <FontAwesomeIcon icon={faArrowLeft}/>;
      default:
        return <> </>;
    }
  }

  private onClickHandler() {
    if (this.props.onClick !== undefined) {
      this.props.onClick(this.props.value);
    }
  }
}
