import moment from "moment";
import React, { Component } from "react";
import { Col, Row, Table } from "react-bootstrap-v5";
import Pagination from "react-responsive-pagination";
import { Link, Redirect } from "react-router-dom";
import Api from "../../../api/Api";
import { IGetContestListRequest } from "../../../api/api-interfaces/contest/IGetContestListRequest";
import withVotingAppAuthorize from "../../../components/common/authorize/withVotingAppAuthorize";
import IconButton from "../../../components/common/buttons/icon-button/IconButton";
import CustomLink from "../../../components/common/custom-link/CustomLink";
import LoadingBar from "../../../components/common/loading-bar/LoadingBar";
import SortingTableTitle from "../../../components/common/sorting-table-title/SortingTableTitle";
import ValidationMessages from "../../../components/common/validation-messages/ValidationMessages";
import ValidationSummary from "../../../components/common/validation-summary/ValidationSummary";
import { CONTEST_SORTING, MOMENT_DATE_FORMAT } from "../../../constants/Constants";
import AuthHelper from "../../../helpers/auth-helper/AuthHelper";
import { scrollToIdHelper } from "../../../helpers/scroll-into-view-helper/scrollToIdHelper";
import ValidationErrors from "../../../helpers/validation-helper/ValidationErrors";
import Validations from "../../../helpers/validation-helper/Validations";
import RoutingConstants from "../../../routes/RoutingConstants";
import DeleteContestModal from "../delete-contest-modal/DeleteContestModal";
import styles from "./ContestList.module.scss";
import { IContestListProps } from "./IContestListProps";
import { IContestListState } from "./IContestListState";
import { toast } from 'react-toastify';

class ContestList extends Component<IContestListProps, IContestListState> {
  constructor(props: IContestListProps) {
    super(props);

    this.state = {
      contests: null,
      page: 1,
      contestId: 0,
      totalPages: 1,
      sorting: CONTEST_SORTING.Name,

      contestIdToDelete: undefined,
      contestNameToDelete: undefined,
      isDeleteContestDialogOpen: false,

      validationErrors: null,
      excludeKeys: ['Page'],
      isLoading: true,
      redirect: null
    };
  }

  render() {
    if (this.state.redirect !== null) {
      let redirect = this.state.redirect;
      return <Redirect push to={redirect}/>;
    }

    return (
      <>
        {
          this.state.validationErrors &&
          <Row>
            <Col md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}}>
              <ValidationSummary errors={this.state.validationErrors} excludeKeys={this.state.excludeKeys}/>
            </Col>
          </Row>
        }

        {
          this.state.isLoading ? <LoadingBar/> :
            <>
              <DeleteContestModal
                isLoading={this.state.isLoading}
                showDialog={this.state.isDeleteContestDialogOpen}
                contestIdToDelete={this.state.contestIdToDelete}
                contestNameToDelete={this.state.contestNameToDelete}
                onConfirm={this.onConfirmDeleteContestClick.bind(this)}
                onCancel={this.onCancelDeleteContestClick.bind(this)}
              />

              <h1 className='headline'>Contests</h1>

              {
                AuthHelper.isCurrentUserAdmin() &&
                <div className='mb-3'>
                  <CustomLink to={RoutingConstants.buildCreateContestUrl(this.state.page, this.state.sorting)}>
                    <IconButton buttonType={'button'} variant={'primary'} size={"sm"} title={'Add new contest'}/>
                  </CustomLink>
                </div>
              }

              <Table striped bordered responsive className={styles.columnWith}>
                <thead>
                <tr>
                  <th className={styles.columnNameWidth}>
                    <SortingTableTitle name="Name"
                                       sorting={this.state.sorting}
                                       sortBy={CONTEST_SORTING.Name}
                                       sortByDesc={CONTEST_SORTING.NameDescending}
                                       onSortByClick={this.sortByNameClick.bind(this)}
                    />
                  </th>
                  <th className='text-center'>
                    <SortingTableTitle name="Not approved entries"
                                       sorting={this.state.sorting}
                                       sortBy={CONTEST_SORTING.NotApproved}
                                       sortByDesc={CONTEST_SORTING.NotApprovedDescending}
                                       onSortByClick={this.sortByNotApprovedClick.bind(this)}
                    />
                  </th>
                  <th className='text-center'>
                    <SortingTableTitle name="Approved entry votes"
                                       sorting={this.state.sorting}
                                       sortBy={CONTEST_SORTING.TotalVotes}
                                       sortByDesc={CONTEST_SORTING.TotalVotesDescending}
                                       onSortByClick={this.sortByTotalVotesClick.bind(this)}
                    />
                  </th>
                  <th>
                    <SortingTableTitle name="Entry submit start"
                                       sorting={this.state.sorting}
                                       sortBy={CONTEST_SORTING.TimeToEntrySubmitStart}
                                       sortByDesc={CONTEST_SORTING.TimeToEntrySubmitStartDescending}
                                       onSortByClick={this.sortByTimeToEntrySubmitStartClick.bind(this)}
                    />
                    &nbsp;/&nbsp;
                    <SortingTableTitle name="end time"
                                       sorting={this.state.sorting}
                                       sortBy={CONTEST_SORTING.TimeToEntrySubmitEnd}
                                       sortByDesc={CONTEST_SORTING.TimeToEntrySubmitEndDescending}
                                       onSortByClick={this.sortByTimeToEntrySubmitEndClick.bind(this)}
                    />
                  </th>
                  <th>
                    <SortingTableTitle name="Voting start"
                                       sorting={this.state.sorting}
                                       sortBy={CONTEST_SORTING.TimeToVotingStart}
                                       sortByDesc={CONTEST_SORTING.TimeToVotingStartDescending}
                                       onSortByClick={this.sortByTimeToVotingStartClick.bind(this)}
                    />
                    &nbsp;/&nbsp;
                    <SortingTableTitle name="end time"
                                       sorting={this.state.sorting}
                                       sortBy={CONTEST_SORTING.TimeToVotingEnd}
                                       sortByDesc={CONTEST_SORTING.TimeToVotingEndDescending}
                                       onSortByClick={this.sortByTimeToVotingEndClick.bind(this)}
                    />
                  </th>
                  {
                    AuthHelper.isCurrentUserAdmin() &&
                    <th className='text-center'/>
                  }
                </tr>
                </thead>
                <tbody>
                {
                  this.state.contests && this.state.contests.length > 0
                    ? this.state.contests.map((contest) => {
                      return (
                        <tr id={`contest_${contest.id}`} key={contest.id}>
                          <td className={styles.columnNameWidth}>
                            <CustomLink to={RoutingConstants.buildContestDetailsUrl(contest.id)}
                                        isActive={contest.isActive}>
                              <span className='text-break'>{contest.name}</span>
                            </CustomLink>
                            {
                              !contest.isActive && <span> - (not active)</span>
                            }
                          </td>
                          <td className='text-center'>{contest.notApprovedEntriesCount}</td>
                          <td className='text-center'>{contest.totalVoteCount}</td>
                          <td>
                            {this.formatDate(contest.entrySubmitStartTime)}<br/>
                            {this.formatDate(contest.entrySubmitEndTime)}
                          </td>
                          <td>
                            {this.formatDate(contest.votingStartTime)} <br/>
                            {this.formatDate(contest.votingEndTime)}
                          </td>
                          {
                            AuthHelper.isCurrentUserAdmin() &&
                            <td className='btnColumn'>
                            <span className="d-flex flex-wrap gap-1 justify-content-center">
                              <Link to={RoutingConstants.buildEditContestUrl(contest.id, this.state.page, this.state.sorting)}>
                                <IconButton iconType={'edit'} size={"sm"} buttonType={'button'}
                                            variant={'outline-primary'}/>
                              </Link>

                              <IconButton iconType={'delete'} size={"sm"} buttonType={'button'}
                                          variant={'outline-danger'}
                                          onClick={() => this.onDeleteContest(contest.id, contest.name)}
                              />
                            </span>
                            </td>
                          }
                        </tr>
                      );
                    })
                    : <tr>
                      <td colSpan={6} className={'text-center'}>No contests</td>
                    </tr>
                }
                </tbody>
              </Table>

              {
                this.state.totalPages > 1 &&
                <Row>
                  <Col md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}} className='mt-2'>
                    <Pagination current={this.state.page}
                                total={this.state.totalPages}
                                onPageChange={(page: number) => this.onContestPageChange(page)}
                    />
                    <ValidationMessages fieldName="page" errors={this.state.validationErrors ?? {}}/>
                  </Col>
                </Row>
              }
            </>
        }
      </>
    );
  }

  async componentDidMount() {
    let isAdmin = AuthHelper.isCurrentUserAdmin();
    let isJudge = AuthHelper.isCurrentUserJudge();
    let page = this.props.page;
    let contestId = this.props.contestId;

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const sortingParam = params.get('sorting');
    const sorting = sortingParam ? sortingParam : this.state.sorting;

    this.setState({contestId: contestId});

    if (!isAdmin && !isJudge) {
      this.setState({redirect: RoutingConstants.NO_PERMITTED});
      return;
    }

    await this.getContests(sorting, page);

    if (contestId) {
      scrollToIdHelper(`contest_${contestId}`);
    }
  }

  async componentDidUpdate(prevProps: Readonly<IContestListProps>, prevState: Readonly<IContestListState>) {
    if (this.props.page !== prevProps.page) {
      this.setState({isLoading: true, redirect: null});
      await this.getContests(this.state.sorting, this.props.page);
    }
  }

  private async getContests(sorting: string, page: number) {
    let request: IGetContestListRequest = {
      sorting: sorting,
      page: page
    };
    try {
      let response = await Api.getContests(request);

      this.setState({
        contests: response.contests,
        page: response.pager.page,
        totalPages: response.pager.totalPages,
        sorting: sorting,
        validationErrors: {},
        isLoading: false
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get contest list from the server', err)
      );
    }
  }

  formatDate(date: Date): string {
    if (!date) return 'Not set';
    return moment(date).format(MOMENT_DATE_FORMAT);
  }

  private async sortByNameClick() {
    if (this.state.sorting === CONTEST_SORTING.Name) {
      this.setState({isLoading: true});
      await this.getContests(CONTEST_SORTING.NameDescending, this.state.page);
    } else {
      this.setState({isLoading: true});
      await this.getContests(CONTEST_SORTING.Name, this.state.page);
    }
  }

  private async sortByNotApprovedClick() {
    if (this.state.sorting === CONTEST_SORTING.NotApproved) {
      this.setState({isLoading: true});
      await this.getContests(CONTEST_SORTING.NotApprovedDescending, this.state.page);
    } else {
      this.setState({isLoading: true});
      await this.getContests(CONTEST_SORTING.NotApproved, this.state.page);
    }
  }

  private async sortByTotalVotesClick() {
    if (this.state.sorting === CONTEST_SORTING.TotalVotes) {
      this.setState({isLoading: true});
      await this.getContests(CONTEST_SORTING.TotalVotesDescending, this.state.page);
    } else {
      this.setState({isLoading: true});
      await this.getContests(CONTEST_SORTING.TotalVotes, this.state.page);
    }
  }

  private async sortByTimeToVotingStartClick() {
    if (this.state.sorting === CONTEST_SORTING.TimeToVotingStart) {
      this.setState({isLoading: true});
      await this.getContests(CONTEST_SORTING.TimeToVotingStartDescending, this.state.page);
    } else {
      this.setState({isLoading: true});
      await this.getContests(CONTEST_SORTING.TimeToVotingStart, this.state.page);
    }
  }

  private async sortByTimeToVotingEndClick() {
    if (this.state.sorting === CONTEST_SORTING.TimeToVotingEnd) {
      this.setState({isLoading: true});
      await this.getContests(CONTEST_SORTING.TimeToVotingEndDescending, this.state.page);
    } else {
      this.setState({isLoading: true});
      await this.getContests(CONTEST_SORTING.TimeToVotingEnd, this.state.page);
    }
  }

  private async sortByTimeToEntrySubmitStartClick() {
    if (this.state.sorting === CONTEST_SORTING.TimeToEntrySubmitStart) {
      this.setState({isLoading: true});
      await this.getContests(CONTEST_SORTING.TimeToEntrySubmitStartDescending, this.state.page);
    } else {
      this.setState({isLoading: true});
      await this.getContests(CONTEST_SORTING.TimeToEntrySubmitStart, this.state.page);
    }
  }

  private async sortByTimeToEntrySubmitEndClick() {
    if (this.state.sorting === CONTEST_SORTING.TimeToEntrySubmitEnd) {
      this.setState({isLoading: true});
      await this.getContests(CONTEST_SORTING.TimeToEntrySubmitEndDescending, this.state.page);
    } else {
      this.setState({isLoading: true});
      await this.getContests(CONTEST_SORTING.TimeToEntrySubmitEnd, this.state.page);
    }
  }

  private async onConfirmDeleteContestClick(contestId: number) {
    this.setState({isLoading: true});

    try {
      await Api.deleteContest(contestId);
      await this.getContests(this.state.sorting, this.state.page);

      let message = 'Contest deletion has been queued in background.';
      ContestList.showSuccessToastMessage(message);
    } catch (err) {
      this.setValidationErrors(Validations.buildApiCommunicationErrors('Can\'t delete contest', err));

      this.closeDeleteContestDialog();
      return;
    }
    this.closeDeleteContestDialog();
  }

  private onCancelDeleteContestClick() {
    this.closeDeleteContestDialog();
  }

  private closeDeleteContestDialog() {
    let state = {...this.state};
    state.contestIdToDelete = undefined;
    state.contestNameToDelete = undefined;
    state.isDeleteContestDialogOpen = false;
    this.setState(state);
  }

  private async onDeleteContest(contestIdToDelete: number, contestNameToDelete: string) {
    let state = {...this.state};
    state.contestIdToDelete = contestIdToDelete;
    state.contestNameToDelete = contestNameToDelete;
    state.isDeleteContestDialogOpen = true;
    this.setState(state);
  }

  private async onContestPageChange(page: number) {
    if (this.state.page !== page) {
      this.setState({page: page, redirect: RoutingConstants.buildContestUrl(page, this.state.contestId)});
    }
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }

  private static showSuccessToastMessage(content: string) {
    toast.success(content);
  }
}

export default withVotingAppAuthorize(ContestList);