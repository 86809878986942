import React, { ChangeEvent, Component } from 'react';
import { Col, Form, InputGroup, Row } from "react-bootstrap-v5";
import ReactModal from 'react-modal';
import { toast } from "react-toastify";
import { ICustomFieldInfo } from "../../../../../api/api-interfaces/contest/ICustomFieldInfo";
import IconButton from "../../../../../components/common/buttons/icon-button/IconButton";
import ToggleButton from "../../../../../components/common/buttons/toggle-button/ToggleButton";
import RequiredField from "../../../../../components/common/required-field/RequiredField";
import ValidationMessages from "../../../../../components/common/validation-messages/ValidationMessages";
import { VerticalSpaceSize } from "../../../../../components/common/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../../../components/common/vertical-space/VerticalSpace";
import { CHECK_ERROR_MESSAGE, CUSTOM_FIELD_CODES } from "../../../../../constants/Constants";
import { scrollIntoViewHelper } from "../../../../../helpers/scroll-into-view-helper/scrollIntoViewHelper";
import ValidationErrors from "../../../../../helpers/validation-helper/ValidationErrors";
import Validations from "../../../../../helpers/validation-helper/Validations";
import styles from '../../../../../styles/ModalWindow.module.scss';
import { IAddCustomFieldModalProps } from "./IAddCustomFieldModalProps";
import { IAddCustomFieldModalState } from "./IAddCustomFieldModalState";

const DEFAULT: string = "DEFAULT";

class AddCustomFieldModal extends Component<IAddCustomFieldModalProps, IAddCustomFieldModalState> {

  private static dialogIndex = 100;
  private readonly InputGroupRef = React.createRef<HTMLInputElement>();

  constructor(props: IAddCustomFieldModalProps) {
    super(props);

    this.state = {
      dialogId: 'add_custom_field_dialog_' + (AddCustomFieldModal.dialogIndex++),

      name: '',
      typeCode: DEFAULT,
      description: '',
      showOnCode: DEFAULT,
      isRequired: false,
      options: null,

      optionValue: '',
      validationErrors: {}
    };
  }

  render() {
    const {showDialog, customFieldTypes, customFieldShowOnValues} = this.props;

    return (
      <ReactModal id={this.state.dialogId}
                  isOpen={showDialog}
                  onRequestClose={this.onCancel.bind(this)}
                  shouldCloseOnOverlayClick={false}
                  shouldCloseOnEsc={false}
                  className={`${styles.content} shadow p-4`}
                  overlayClassName={`${styles.overlay}`}
                  appElement={document.getElementById('root') as HTMLElement}>

        <h3 className={'text-center'}>Add new field</h3>

        <Form>
          <Row>
            <Col md={8}>
              <Form.Label>Name<RequiredField/></Form.Label>
              <Form.Control name="Name" type="text" placeholder="Please enter name"
                            value={this.state.name}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onNameChange(e)}/>
              <ValidationMessages fieldName="Name" errors={this.state.validationErrors}/>
            </Col>
          </Row>
          <VerticalSpace size={VerticalSpaceSize.small}/>

          <Row>
            <Col md={8}>
              <Form.Label>Type<RequiredField/></Form.Label>
              <Form.Control name="Type" as='select' className='form-select'
                            value={this.state.typeCode}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => this.onTypeChange(e)}
              >
                <option value={DEFAULT}>-- Please select --</option>
                {
                  customFieldTypes.map((cf) => {
                    return <option key={cf.code} value={cf.code}>{cf.name}</option>;
                  })
                }
              </Form.Control>
              <ValidationMessages fieldName="Type" errors={this.state.validationErrors}/>
            </Col>
          </Row>
          <VerticalSpace size={VerticalSpaceSize.small}/>

          {
            this.state.typeCode === CUSTOM_FIELD_CODES.RadioButton &&
            <>
              <Row>
                <Col md={8}>
                  <Form.Label>Radiobutton values</Form.Label>
                  <ul>
                    {
                      this.state.options && this.state.options.map((option, index) => {
                        return (
                          <Row key={index} className='mb-2'>
                            <Col md={8} className='text-break'>
                              <li>{option.text}</li>
                            </Col>
                            <Col className="d-flex gap-1 justify-content-end align-items-center">

                              <IconButton iconType={'up'}
                                          buttonType={'button'}
                                          variant={'outline-primary'}
                                          onClick={() => this.onUpOptionValue(index)}
                                          size={"sm"}
                              />

                              <IconButton iconType={'down'}
                                          buttonType={'button'}
                                          variant={'outline-primary'}
                                          onClick={() => this.onDownOptionValue(index)}
                                          size={"sm"}
                              />

                              <IconButton iconType={'delete'}
                                          buttonType={'button'}
                                          variant={'outline-danger'}
                                          onClick={() => this.onDeleteOptionValue(index)}
                                          size={"sm"}
                              />
                            </Col>
                          </Row>
                        );
                      })

                    }

                  </ul>
                  <InputGroup ref={this.InputGroupRef}>
                    <Form.Control name="RadiobuttonValue" type="text"
                                  placeholder="Please enter radiobutton value"
                                  value={this.state.optionValue}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onOptionValueChange(e)}
                                  onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => e.key === 'Enter' && this.onAddOptionValueClick()}
                    />

                    <IconButton onClick={this.onAddOptionValueClick.bind(this)} variant="outline-primary"
                                iconType={'check'}/>
                  </InputGroup>
                  <ValidationMessages fieldName="OptionValue" errors={this.state.validationErrors}/>
                </Col>
              </Row>
              <VerticalSpace size={VerticalSpaceSize.small}/>
            </>
          }

          {
            this.state.typeCode === CUSTOM_FIELD_CODES.DropDownList &&
            <>
              <Row>
                <Col md={8}>
                  <Form.Label>Dropdown values</Form.Label>
                  <ul>
                    {
                      this.state.options && this.state.options.map((option, index) => {
                        return (
                          <Row key={index} className='mb-2'>
                            <Col md={8} className='text-break'>
                              <li>{option.text}</li>
                            </Col>
                            <Col className="d-flex gap-1 justify-content-end align-items-center">

                              <IconButton iconType={'up'}
                                          buttonType={'button'}
                                          variant={'outline-primary'}
                                          onClick={() => this.onUpOptionValue(index)}
                                          size={"sm"}
                              />

                              <IconButton iconType={'down'}
                                          buttonType={'button'}
                                          variant={'outline-primary'}
                                          onClick={() => this.onDownOptionValue(index)}
                                          size={"sm"}
                              />

                              <IconButton iconType={'delete'}
                                          buttonType={'button'}
                                          variant={'outline-danger'}
                                          onClick={() => this.onDeleteOptionValue(index)}
                                          size={"sm"}
                              />
                            </Col>
                          </Row>
                        );
                      })

                    }
                  </ul>

                  <InputGroup ref={this.InputGroupRef}>
                    <Form.Control name="DropdownValue" type="text" placeholder="Please enter dropdown value"
                                  value={this.state.optionValue}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onOptionValueChange(e)}
                                  onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => e.key === 'Enter' && this.onAddOptionValueClick()}
                    />

                    <IconButton onClick={this.onAddOptionValueClick.bind(this)} variant="outline-primary"
                                iconType={'check'}/>
                  </InputGroup>
                  <ValidationMessages fieldName="OptionValue" errors={this.state.validationErrors}/>
                </Col>
              </Row>
              <VerticalSpace size={VerticalSpaceSize.small}/>
            </>
          }

          <Row>
            <Col md={8}>
              <Form.Label>Description</Form.Label>
              <Form.Control name="Description" type="text" placeholder="Please enter description"
                            value={this.state.description}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => this.onDescriptionChange(e)}
              />
              <ValidationMessages fieldName="Description" errors={this.state.validationErrors}/>
            </Col>
          </Row>
          <VerticalSpace size={VerticalSpaceSize.small}/>

          <Row>
            <Col md={8}>
              <Form.Label>Show on<RequiredField/></Form.Label>
              <Form.Control name="ShowOnCode" as='select' className='form-select'
                            value={this.state.showOnCode}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => this.onShowOnCodeChange(e)}
              >
                <option value={DEFAULT}>-- Please select --</option>
                {
                  customFieldShowOnValues.map((so) => {
                    return <option key={so.code} value={so.code}>{so.name}</option>;
                  })
                }
              </Form.Control>
              <ValidationMessages fieldName="ShowOnCode" errors={this.state.validationErrors}/>
            </Col>
          </Row>
          <VerticalSpace size={VerticalSpaceSize.small}/>

          <Row className='align-items-center'>
            <Col xs={7} sm={6} xxl={4}>
              <strong className='text-break'>Required:</strong>
            </Col>
            <Col>
              <ToggleButton selected={this.state.isRequired}
                            toggleSelected={(isRequired) => this.onIsRequiredChange(isRequired)}/>
            </Col>
          </Row>
          <VerticalSpace size={VerticalSpaceSize.small}/>

          <div className="d-flex justify-content-end">
            <IconButton onClick={this.onAddCustomFieldClick.bind(this)} variant="outline-primary"
                        title="Add" styles={{marginRight: '1em'}}/>

            <IconButton onClick={this.onCancel.bind(this)} variant="outline-secondary" title="Cancel"/>
          </div>
        </Form>
      </ReactModal>
    );
  }

  private onNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    let errors = Validations.deleteErrors(this.state.validationErrors, 'Name');
    this.setState({name: e.target.value, validationErrors: errors});
  }

  private onTypeChange(e: React.ChangeEvent<HTMLInputElement>) {
    let state = {...this.state};
    let errors = Validations.deleteErrors(this.state.validationErrors, 'Type');

    state.typeCode = e.target.value;
    state.validationErrors = errors;

    if (state.typeCode === CUSTOM_FIELD_CODES.RadioButton || state.typeCode === CUSTOM_FIELD_CODES.DropDownList) {
      state.options = [];
      state.optionValue = '';
    } else {
      state.options = null;
      state.optionValue = '';
    }

    this.setState(state);
  }

  private onUpOptionValue(indexToUp: number) {
    if (this.state.options) {
      let optionValues = [...this.state.options];

      if (indexToUp === 0) {
        return;
      }

      if (indexToUp > 0) {
        optionValues.splice(indexToUp - 1, 0, optionValues.splice(indexToUp, 1)[0]);
      }

      this.setState({options: optionValues});
    }
  }

  private onDownOptionValue(indexToDown: number) {
    if (this.state.options) {
      let options = [...this.state.options];

      if (indexToDown + 1 === options.length) {
        return;
      }

      if (indexToDown !== options.length) {
        options.splice(indexToDown + 1, 0, options.splice(indexToDown, 1)[0]);
      }

      this.setState({options: options});
    }
  }

  private onDeleteOptionValue(indexToDelete: number) {
    let state = {...this.state};
    state.options?.splice(indexToDelete, 1);
    this.setState(state);
  }

  private onAddOptionValueClick() {
    let state = {...this.state};

    let isOptionValueValid: boolean = true;

    if (!this.state.optionValue || this.state.optionValue.trim().length < 1) {
      state.validationErrors = Validations.setErrors({...state.validationErrors}, 'OptionValue', ["Option text is required"]);
      this.setState(state);
      return isOptionValueValid = false;
    }

    if (this.state.options) {
      this.state.options.forEach(x => {
        if (x.text === this.state.optionValue) {
          state.validationErrors = Validations.setErrors({...state.validationErrors}, 'OptionValue', ["Options with the same name are not allowed"]);
          this.setState(state);
          return isOptionValueValid = false;
        }
      });
    }

    if (isOptionValueValid) {
      state.validationErrors = Validations.deleteErrors(this.state.validationErrors, 'OptionValue');
      state.options?.push({text: state.optionValue});
      state.optionValue = '';
      this.setState(state);
    }

    this.scrollToRadiobuttonInput();
  }

  scrollToRadiobuttonInput = () => {
    if (this.InputGroupRef && this.InputGroupRef.current) {
      return this.InputGroupRef.current.scrollIntoView();
    }
  };

  private onOptionValueChange(e: React.ChangeEvent<HTMLInputElement>) {
    let errors = Validations.deleteErrors(this.state.validationErrors, 'OptionValue');
    this.setState({optionValue: e.target.value, validationErrors: errors});
  }

  private onDescriptionChange(e: React.ChangeEvent<HTMLInputElement>) {
    let errors = Validations.deleteErrors(this.state.validationErrors, 'Description');
    this.setState({description: e.target.value, validationErrors: errors});
  }

  private onIsRequiredChange(isRequired: boolean) {
    this.setState({isRequired: !isRequired});
  }

  private onShowOnCodeChange(e: React.ChangeEvent<HTMLInputElement>) {
    let errors = Validations.deleteErrors(this.state.validationErrors, 'ShowOnCode');
    this.setState({showOnCode: e.target.value, validationErrors: errors});
  }

  private onAddCustomFieldClick() {
    let errors = this.validateForm();

    if (Object.keys(errors).length > 0) {
      this.setState({validationErrors: errors});
      AddCustomFieldModal.showErrorToastMessage();
      scrollIntoViewHelper(errors);
      return;
    }

    if (this.props.onAddCustomFieldClick) {
      let customField: ICustomFieldInfo = {
        name: this.state.name,
        typeCode: this.state.typeCode,
        optionValue: this.state.optionValue,
        options: this.state.options,
        description: this.state.description,
        showOnCode: this.state.showOnCode,
        isRequired: this.state.isRequired,
        order: 1
      };

      this.props.onAddCustomFieldClick(customField);
    }

    this.clearState();
  }

  private validateForm(): ValidationErrors {
    let errors: ValidationErrors = {};

    if (!this.state.name || this.state.name.trim().length < 1) {
      errors = Validations.setErrors({...errors}, 'Name', ['Name is required']);
    }

    if (this.state.name && this.state.name.trim().length > 200) {
      errors = Validations.setErrors({...errors}, 'Name', ["Name can't be longer than 200 characters"]);
    }

    if (this.state.showOnCode === DEFAULT) {
      errors = Validations.setErrors({...errors}, 'ShowOnCode', ['Show on is required']);
    }

    if (this.state.typeCode === DEFAULT) {
      errors = Validations.setErrors({...errors}, 'Type', ['Type is required']);
    }

    if (this.state.typeCode === CUSTOM_FIELD_CODES.RadioButton && this.state.options && this.state.options.length < 1) {
      errors = Validations.setErrors({...errors}, 'OptionValue', ['Please enter at least one value']);
    }

    if (this.state.typeCode === CUSTOM_FIELD_CODES.DropDownList && this.state.options && this.state.options.length < 1) {
      errors = Validations.setErrors({...errors}, 'OptionValue', ['Please enter at least one value']);
    }

    if (this.state.options) {
      this.state.options.forEach(x => {
        if (x.text === this.state.optionValue) {
          errors = Validations.setErrors({...errors}, 'OptionValue', ["Options with the same name are not allowed"]);
        }
      });
    }

    return errors;
  }

  private onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }

    this.clearState();
  }

  private clearState() {
    let state = {...this.state};
    state.name = '';
    state.typeCode = DEFAULT;
    state.description = '';
    state.optionValue = '';
    state.options = null;
    state.showOnCode = DEFAULT;
    state.isRequired = false;
    state.validationErrors = {};
    this.setState(state);
  }

  private static showErrorToastMessage() {
    toast.error(CHECK_ERROR_MESSAGE);
  }
}

export default AddCustomFieldModal;