import {
  faArrowCircleLeft,
  faArrowCircleRight,
  faDoorOpen,
  faListAlt,
  faUserCog
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Menu, MenuItem, ProSidebar, SidebarContent, SidebarFooter, SidebarHeader, } from 'react-pro-sidebar';
import { Link } from "react-router-dom";
import kiwanisLogoImg from '../../../assets/KI_logo.png';
import kiwanisLogoAndTextImg from '../../../assets/KI_logo_NEW.png';
import sidebarBg from '../../../assets/sidebar-bg.png';
import AuthHelper from "../../../helpers/auth-helper/AuthHelper";
import RoutingConstants from "../../../routes/RoutingConstants";
import { ISidebarProps } from "./ISidebarProps";
import { ISidebarState } from "./ISidebarState";
import styles from "./Sidebar.module.scss";

class Sidebar extends React.Component<ISidebarProps, ISidebarState> {
  interval: any;

  constructor(props: ISidebarProps) {
    super(props);

    this.state = {
      collapsed: false,
      userFullName: ''
    };

    this.signOut = this.signOut.bind(this);
    this.collapseSidebar = this.collapseSidebar.bind(this);
  }

  render() {
    let {toggled, handleToggleSidebar} = this.props;

    return (
      <ProSidebar image={sidebarBg} toggled={toggled} breakPoint="md" onToggle={handleToggleSidebar}
                  collapsed={this.state.collapsed}>
        <SidebarHeader>
          <div className={styles.kiwanisBrand}>
            <Link to={RoutingConstants.buildContestUrl(1, 0)}>
              <span className={styles.kiwanisIconLogo} hidden={!this.state.collapsed}>
                <img src={kiwanisLogoImg} alt="Kiwanis Logo"/>
              </span>
              <span className={styles.kiwanisLogo} hidden={this.state.collapsed}>
                <img src={kiwanisLogoAndTextImg} alt="Kiwanis logo"/>
              </span>
            </Link>
          </div>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape="square">
            {
              (AuthHelper.isCurrentUserAdmin() || AuthHelper.isCurrentUserJudge()) &&
              <MenuItem icon={<FontAwesomeIcon icon={faListAlt} size={'lg'}/>}
                        onClick={(toggle) => handleToggleSidebar(!toggle)}>
                <Link to={RoutingConstants.buildContestUrl(1, 0)}>Contests</Link>
              </MenuItem>
            }

            {
              AuthHelper.isCurrentUserAdmin() &&
              <MenuItem icon={<FontAwesomeIcon icon={faUserCog} size={'lg'}/>}
                        onClick={(toggle) => handleToggleSidebar(!toggle)}>
                <Link to={RoutingConstants.buildUsersUrl(1, 0)}>Users</Link>
              </MenuItem>
            }

            <MenuItem icon={this.state.collapsed
              ? <FontAwesomeIcon icon={faArrowCircleRight} size={'lg'}/>
              : <FontAwesomeIcon icon={faArrowCircleLeft} size={'lg'}/>}
                      onClick={this.collapseSidebar}>
              Collapse sidebar
            </MenuItem>
          </Menu>
        </SidebarContent>

        <SidebarFooter style={{textAlign: 'center'}}>
          <div className='text-break' style={{padding: '0 15px'}}>
            {this.state.collapsed ? '' : this.state.userFullName.substring(0, 100)} {this.state.userFullName.length >= 100 && `...`}
          </div>
          <div className="sidebar-btn-wrapper" style={{padding: '20px 24px'}}>
            <Link to={RoutingConstants.LOGIN} onClick={this.signOut} style={{textDecoration: 'none'}}>
              <div className="sidebar-btn">
                <FontAwesomeIcon icon={faDoorOpen}/>
                <span style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', cursor: "pointer"}}>
                Sign out
              </span>
              </div>
            </Link>
          </div>
        </SidebarFooter>
      </ProSidebar>
    );
  }

  componentDidMount(): void {
    setTimeout(() => this.setState({userFullName: AuthHelper.getUserFullName()}), 100);

    this.interval = setInterval(() => {
      this.setState({userFullName: AuthHelper.getUserFullName()});
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  signOut() {
    AuthHelper.removeSession();
  }

  private collapseSidebar() {
    let state = {...this.state};
    state.collapsed = !state.collapsed;
    this.setState(state);
  }
}

export default Sidebar;
