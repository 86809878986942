import React, { Component } from 'react';
import { Alert } from 'react-bootstrap-v5';
import { IValidationSummaryProps } from './IValidationSummaryProps';
import styles from './ValidationSummary.module.scss';

export default class ValidationSummary extends Component<IValidationSummaryProps> {
  render() {
    if (this.props.errors === undefined || this.props.errors === null) {
      return <></>;
    }

    let matchingMessages: string[] = [];

    for (const field in this.props.errors) {
      if (Object.prototype.hasOwnProperty.call(this.props.errors, field)) {
        if (this.props.excludeKeys === undefined || !this.props.excludeKeys.includes(field)) {
          const fieldMessages = this.props.errors[field];
          matchingMessages = matchingMessages.concat(fieldMessages);
        }
      }
    }

    if (matchingMessages === undefined || matchingMessages.length === 0) {
      return <></>;
    }

    return (
      <Alert variant="danger">
        <ul className={styles.summary}>
          {matchingMessages.map((x, i) => <li key={i}>{x}</li>)}
        </ul>
      </Alert>
    );
  }
}
