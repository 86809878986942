import { ICodeAndNameInfo } from "../api/api-interfaces/common/ICodeAndNameInfo";

export const KIWANIS_BLUE_COLOR: string = '#003874';
export const KIWANIS_GOLD_COLOR: string = '#B49759';
export const KIWANIS_BACKGROUND_COLOR: string = '#F0EADE';
export const KIWANIS_BACKGROUND_TEXT_COLOR: string = '#494743';

export const EMAIL_REGEX: RegExp = new RegExp(/^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/);

export const DATE_ISO_FORMAT: string = "YYYY-MM-DD";
export const DATE_TIME_ISO_FORMAT: string = "YYYY-MM-DDTHH:mm:ss";
export const DATE_PICKER_FORMAT: string = 'MM/dd/yyyy h:mm aa';
export const TIME_PICKER_FORMAT: string = 'h:mm aa';
export const MOMENT_DATE_FORMAT: string = 'MM/DD/YYYY h:mm a';

export const CHECK_ERROR_MESSAGE: string = 'Please check validation errors!';

export const OK: string = "ok";
export const UNKNOWN_OR_EXPIRED_CODE: string = "UnknownOrExpiredCode";
export const SIGN_IN_TEMPORARILY_BLOCKED_FOR_USER: string = "SignInTemporarilyBlockedForUser";

export const DEFAULT: string = "DEFAULT";

// -------------------------------------- CODES & OPTIONS -------------------------------------- //

export const USER_ROLE_CODES = {
  Administrator: "ADMN"
};

export const VOTING_RULE_CODES = {
  OneTime: "ONCE",
  OncePerDay: "1PD_",
  OncePerDayPerEntry: "1PED",
  OncePerEntry: "1PE_",
};

export const CUSTOM_FIELD_CODES = {
  Text: 'TEXT',
  TextArea: 'AREA',
  RadioButton: 'RBTN',
  DropDownList: 'DRDW',
  Checkbox: 'CHBX',
  DatePicker: 'DATE'
};

export const CUSTOM_FIELDS_SHOW_ON_CODES = {
  DetailsAndTitles: 'DTTL',
  DetailsOnly: 'DETA',
  StaffOnly: 'STAF',
  StaffAndSubmitterAfterWin: 'SBMT',
  StaffAndSubmitter: 'SBST'
};

export const CUSTOM_FIELDS_SHOW_ON_NAMES = {
  DetailsAndTitles: 'On details view and on tiles',
  DetailsOnly: 'Only on details view',
  StaffOnly: 'Staff only - not visible to voters or person that has submitted entry',
  StaffAndSubmitterAfterWin: 'Staff and submitter after win',
  StaffAndSubmitter: 'Staff and submitter'
};

export const VOTING_RULES_OPTIONS: ICodeAndNameInfo[] = [
  {code: VOTING_RULE_CODES.OneTime, name: "One time"},
  {code: VOTING_RULE_CODES.OncePerDay, name: "Once per day"},
  {code: VOTING_RULE_CODES.OncePerDayPerEntry, name: "Once per entry per day"},
  {code: VOTING_RULE_CODES.OncePerEntry, name: "Multiple times, but no more than once per each entry"}
];

export const CUSTOM_FIELD_OPTIONS: ICodeAndNameInfo[] = [
  {code: CUSTOM_FIELD_CODES.Text, name: 'Text'},
  {code: CUSTOM_FIELD_CODES.TextArea, name: 'Textarea'},
  {code: CUSTOM_FIELD_CODES.RadioButton, name: 'Radio button'},
  {code: CUSTOM_FIELD_CODES.DropDownList, name: 'Dropdown'},
  {code: CUSTOM_FIELD_CODES.Checkbox, name: 'Checkbox'},
  {code: CUSTOM_FIELD_CODES.DatePicker, name: 'Date picker'}
];

export const CUSTOM_FIELDS_SHOW_ON_OPTIONS: ICodeAndNameInfo[] = [
  {code: CUSTOM_FIELDS_SHOW_ON_CODES.DetailsAndTitles, name: CUSTOM_FIELDS_SHOW_ON_NAMES.DetailsAndTitles },
  {code: CUSTOM_FIELDS_SHOW_ON_CODES.DetailsOnly, name: CUSTOM_FIELDS_SHOW_ON_NAMES.DetailsOnly},
  {code: CUSTOM_FIELDS_SHOW_ON_CODES.StaffOnly, name: CUSTOM_FIELDS_SHOW_ON_NAMES.StaffOnly},
  {code: CUSTOM_FIELDS_SHOW_ON_CODES.StaffAndSubmitterAfterWin, name: CUSTOM_FIELDS_SHOW_ON_NAMES.StaffAndSubmitterAfterWin},
  {code: CUSTOM_FIELDS_SHOW_ON_CODES.StaffAndSubmitter, name: CUSTOM_FIELDS_SHOW_ON_NAMES.StaffAndSubmitter}
];

export const APPROVED_CODES = {
  NotSelected: 'NotSelected',
  Approved: 'Approved',
  NotApproved: 'NotApproved'
};

export const APPROVED_OPTIONS: ICodeAndNameInfo[] = [
  {code: APPROVED_CODES.NotSelected, name: '-- Not selected --'},
  {code: APPROVED_CODES.Approved, name: 'Approved'},
  {code: APPROVED_CODES.NotApproved, name: 'Not approved'}
];

export const MONTHS_OPTIONS: ICodeAndNameInfo[] = [
  {code: "0", name: '-- Not selected --'},
  {code: "1", name: 'January'},
  {code: "2", name: 'February'},
  {code: "3", name: 'March'},
  {code: "4", name: 'April'},
  {code: "5", name: 'May'},
  {code: "6", name: 'June'},
  {code: "7", name: 'July'},
  {code: "8", name: 'August'},
  {code: "9", name: 'September'},
  {code: "10", name: 'October'},
  {code: "11", name: 'November'},
  {code: "12", name: 'December'}
];

export const NOTIFICATION_NEEDED = {
  None: 'None',
  FromFutureToPast: 'FromFutureToPast',
  FromPastToFuture: 'FromPastToFuture'
};

// -------------------------------------- SORTING -------------------------------------- //

export const CONTEST_SORTING = {
  Name: 'Name',
  NameDescending: 'NameDescending',

  NotApproved: 'NotApproved',
  NotApprovedDescending: 'NotApprovedDescending',

  TotalVotes: 'TotalVotes',
  TotalVotesDescending: 'TotalVotesDescending',

  TimeToVotingStart: 'TimeToVotingStart',
  TimeToVotingStartDescending: 'TimeToVotingStartDescending',

  TimeToVotingEnd: 'TimeToVotingEnd',
  TimeToVotingEndDescending: 'TimeToVotingEndDescending',

  TimeToEntrySubmitStart: 'TimeToEntrySubmitStart',
  TimeToEntrySubmitStartDescending: 'TimeToEntrySubmitStartDescending',

  TimeToEntrySubmitEnd: 'TimeToEntrySubmitEnd',
  TimeToEntrySubmitEndDescending: 'TimeToEntrySubmitEndDescending',
};

export const ENTRY_SORTING = {
  Title: 'Title',
  TitleDescending: 'TitleDescending',
  SubmitTime: 'SubmitTime',
  SubmitTimeDescending: 'SubmitTimeDescending',
  VotesCount: 'VotesCount',
  VotesCountDescending: 'VotesCountDescending',
};

export const VOTE_SORTING = {
  Amount: 'Amount',
  AmountDescending: 'AmountDescending',
  SubmitTime: 'SubmitTime',
  SubmitTimeDescending: 'SubmitTimeDescending',
  SubmitterEmail: 'SubmitterEmail',
  SubmitterEmailDescending: 'SubmitterEmailDescending',
  SubmitterFirstName: 'SubmitterFirstName',
  SubmitterFirstNameDescending: 'SubmitterFirstNameDescending',
  SubmitterLastName: 'SubmitterLastName',
  SubmitterLastNameDescending: 'SubmitterLastNameDescending',
};

export const USER_SORTING = {
  Email: 'Email',
  EmailDescending: 'EmailDescending',
  FirstName: 'FirstName',
  FirstNameDescending: 'FirstNameDescending',
  LastName: 'LastName',
  LastNameDescending: 'LastNameDescending',
};