import { faKey, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { Component } from 'react';
import { Card, Col, Form, Row } from "react-bootstrap-v5";
import { Redirect } from 'react-router-dom';
import Api from "../../api/Api";
import { IGetTokenRequest } from "../../api/api-interfaces/login/IGetTokenRequest";
import { IGetTokenResponse } from "../../api/api-interfaces/login/IGetTokenResponse";
import { ISendSignInCodeRequest } from "../../api/api-interfaces/login/ISendSignInCodeRequest";
import withVotingAppAuthorize from "../../components/common/authorize/withVotingAppAuthorize";
import IconButton from "../../components/common/buttons/icon-button/IconButton";
import ValidationMessages from "../../components/common/validation-messages/ValidationMessages";
import ValidationSummary from "../../components/common/validation-summary/ValidationSummary";
import { VerticalSpaceSize } from "../../components/common/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../components/common/vertical-space/VerticalSpace";
import {
  EMAIL_REGEX,
  KIWANIS_BLUE_COLOR,
  SIGN_IN_TEMPORARILY_BLOCKED_FOR_USER,
  UNKNOWN_OR_EXPIRED_CODE
} from "../../constants/Constants";
import AuthHelper from "../../helpers/auth-helper/AuthHelper";
import { scrollIntoViewHelper } from "../../helpers/scroll-into-view-helper/scrollIntoViewHelper";
import ValidationErrors from "../../helpers/validation-helper/ValidationErrors";
import Validations from "../../helpers/validation-helper/Validations";
import RoutingConstants from "../../routes/RoutingConstants";
import { ILoginProps } from "./ILoginProps";
import { ILoginState } from "./ILoginState";
import styles from './Login.module.scss';
import ReCaptcha from "../../components/common/recaptcha/ReCaptcha";

class LoginPage extends Component<ILoginProps, ILoginState> {
  _isMounted = false;
  private readonly recaptchaRef1 = React.createRef<ReCaptcha>();
  private readonly recaptchaRef2 = React.createRef<ReCaptcha>();

  constructor(props: ILoginProps) {
    super(props);

    this.state = {
      email: AuthHelper.getEmail() ?? '',
      verificationCode: "",

      isRecaptchaEnabled: false,
      recaptchaSiteKey: '',

      isEmailSend: false,
      recaptchaResponse: null,

      validationErrors: null,
      excludeKeys: ["Email", "VerificationCode", "RecaptchaResponse"],
      isLoading: true,
      redirect: null
    };
  }

  render() {
    if (this.state.redirect !== null) {
      return <Redirect push to={this.state.redirect}/>;
    }

    if (this.props.auth.isAuthenticated) {
      return <Redirect push to={RoutingConstants.buildContestUrl(1, 0)}/>;
    }

    return (
      <>
        {
          this.state.validationErrors &&
          <Row>
            <Col md={{span: 6, offset: 3}}>
              <ValidationSummary errors={this.state.validationErrors} excludeKeys={this.state.excludeKeys}/>
            </Col>
          </Row>
        }

        <div className={`rounded ${styles.loginWrapper} d-flex justify-content-center`}>
          <Card className={`shadow ${styles.loginCard}`}>
            <h1 className="text-center my-2 headline">Kiwanis Voting App</h1>

            {
              !this.state.isEmailSend &&
              <form noValidate className="d-flex flex-column"
                    onSubmit={(e) => this.onSendSignInCode(e)}>
                <div className={`d-flex align-items-center ${styles.inputField}`}>
                  <span className="px-2"><FontAwesomeIcon icon={faUser} color={KIWANIS_BLUE_COLOR}/></span>
                  <Form.Control id='email' type="email" name="voting-app-email" placeholder="Please enter email"
                                className={styles.formControl}
                                value={this.state.email}
                                onChange={this.onEmailChange.bind(this)}
                  />
                </div>
                <ValidationMessages fieldName="Email" errors={this.state.validationErrors ?? {}}/>

                <div className="d-flex justify-content-center mt-3">
                  {
                    this.state.isRecaptchaEnabled &&
                    <ReCaptcha ref={this.recaptchaRef1} recaptchaSiteKey={this.state.recaptchaSiteKey}/>
                  }
                </div>
                <ValidationMessages fieldName="RecaptchaResponse" errors={this.state.validationErrors ?? {}}/>

                <div className="d-grid gap-2 mt-3">
                  <IconButton buttonType={"submit"} variant="primary" title="Send code to email"
                              isLoading={this.state.isLoading}
                              disabled={this.state.isLoading}
                  />
                </div>
              </form>
            }

            {
              this.state.isEmailSend &&

              <form noValidate className="d-flex flex-column"
                    onSubmit={(e) => this.onSubmitLogin(e)}>
                {
                  this.state.email &&
                  <div className='text-center text-break mb-2'>
                    A code has been sent to <strong>{this.state.email}</strong>
                  </div>
                }
                <div className={`d-flex align-items-center ${styles.inputField}`}>
                  <span className="px-2"><FontAwesomeIcon icon={faKey} color={KIWANIS_BLUE_COLOR}/></span>
                  <Form.Control type="text" name="voting-app-verification-code"
                                autoFocus={true}
                                placeholder="Please enter code here"
                                className={styles.formControl}
                                value={this.state.verificationCode}
                                onChange={this.onVerificationCodeChange.bind(this)}
                  />
                </div>
                <ValidationMessages fieldName="VerificationCode" errors={this.state.validationErrors ?? {}}/>

                <div className="d-flex justify-content-center mt-3">
                  {
                    this.state.isRecaptchaEnabled &&
                    <ReCaptcha ref={this.recaptchaRef2} recaptchaSiteKey={this.state.recaptchaSiteKey}/>
                  }
                </div>
                <ValidationMessages fieldName="RecaptchaResponse" errors={this.state.validationErrors ?? {}}/>

                <div className="d-grid gap-2 my-3">
                  <IconButton buttonType={"submit"} variant="primary" title="Sign in"
                              isLoading={this.state.isLoading} disabled={this.state.isLoading}
                  />
                </div>

                <div className="d-grid gap-2">
                  <IconButton buttonType={"button"} variant="secondary" title="Back"
                              onClick={this.onBack.bind(this)}
                  />
                </div>
              </form>
            }

            <VerticalSpace size={VerticalSpaceSize.normal}/>
          </Card>
        </div>
      </>
    );
  }

  async componentDidMount() {
    this._isMounted = true;

    await this.getSignInDetails();
  }

  private async getSignInDetails() {
     try {
      let response = await Api.getSignInDetails();

      this.setState({
        isRecaptchaEnabled: response.isRecaptchaEnabled,
        recaptchaSiteKey: response.recaptchaSiteKey,
        isLoading: false,
        validationErrors: {}
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get sign in details from the server', err)
      );
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  private onEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    let errors = Validations.deleteErrors(this.state.validationErrors ?? {}, 'Email');
    this.setState({email: e.target.value, validationErrors: errors});
  }

  private onVerificationCodeChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setValidationErrors({});
    this.setState({verificationCode: e.target.value});
  }

  async onSendSignInCode(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    let errors = this.validateForm();
    if (Object.keys(errors).length > 0) {
      this.setState({validationErrors: errors});
      scrollIntoViewHelper(errors);
      return;
    }

    await this.recaptchaRef1.current?.reset();
    const token = await this.recaptchaRef1.current?.getToken();

    this.setState({isLoading: true});

    let request: ISendSignInCodeRequest = {
      email: this.state.email,
      recaptchaResponse: token ? token : null
    };

    this.setState({isLoading: true});

    try {
      await Api.sendSignInCode(request);

      this.setState({isLoading: false});
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Send sign in code has failed', err)
      );

      return;
    }

    this.setState({isEmailSend: true, isLoading: false});
  }

  private validateForm(): ValidationErrors {
    let errors: ValidationErrors = {};

    if (!EMAIL_REGEX.test(this.state.email)) {
      errors = Validations.setErrors({...errors}, 'Email', ['Email format is invalid.']);
    }

    if (!this.state.email || this.state.email.trim().length < 1) {
      errors = Validations.setErrors({...errors}, 'Email', ['Email is required.']);
    }
    
    return errors;
  }

  private onBack() {
    let state = {...this.state};
    state.isEmailSend = false;
    state.recaptchaResponse = null;
    state.validationErrors = {};
    state.verificationCode = '';
    this.setState(state);
  }

  async onSubmitLogin(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    let errors = this.validateVerificationCode();
    if (Object.keys(errors).length > 0) {
      this.setState({validationErrors: errors});
      scrollIntoViewHelper(errors);
      return;
    }

    await this.recaptchaRef2.current?.reset();
    const token = await this.recaptchaRef2.current?.getToken();

    this.setState({isLoading: true});

    let data: IGetTokenRequest = {
      email: this.state.email,
      recaptchaResponse: token ? token : null,
      verificationCode: this.state.verificationCode
    };

    try {
      let response: IGetTokenResponse = await Api.getToken(data);

      if (response.status === UNKNOWN_OR_EXPIRED_CODE) {
        this.addValidationError('VerificationCode', 'Unknown or expired code');
        return;
      }

      if (response.status === SIGN_IN_TEMPORARILY_BLOCKED_FOR_USER) {
        this.addValidationError('VerificationCode', 'Sign in temporarily blocked for user');
        return;
      }

      let expires = moment(response.expirationTimeUtc);
      AuthHelper.setUserFullName(response.userFullName, expires.toDate());
      AuthHelper.setIsCurrentUserAdmin(response.isAdmin, expires.toDate());

      let expiresEmailDate = moment().add(1, 'year').toDate();
      AuthHelper.setEmail(this.state.email, expiresEmailDate);

      let judgeContestIds = JSON.stringify(response.judgeContestIds ? response.judgeContestIds : '[]');
      AuthHelper.setJudgeContestIds(judgeContestIds, expires.toDate());

      AuthHelper.setAccessToken(response.token, expires.toDate());

      if (this._isMounted) {
        this.setState({isLoading: false});
      }
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Get auth token has failed', err)
      );

      return;
    }

    if (this._isMounted) {
      this.setState({redirect: RoutingConstants.buildContestUrl(1,0)});
    }
  }

  private validateVerificationCode(): ValidationErrors {
    let errors: ValidationErrors = {};

    if (!this.state.verificationCode || this.state.verificationCode.trim().length < 1) {
      errors = Validations.setErrors({...errors}, 'VerificationCode', ['Verification code is required']);
    }

    return errors;
  }

  private addValidationError(key: string, message: string) {
    let state = {...this.state};
    state.validationErrors = {};
    state.isLoading = false;
    Validations.addError(state.validationErrors, key, message);
    this.setState(state);
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }
}

export default withVotingAppAuthorize(LoginPage);