import React, { Component } from 'react';
import { Col, Form, FormControl, Row } from "react-bootstrap-v5";
import ReactModal from 'react-modal';
import IconButton from "../../../../components/common/buttons/icon-button/IconButton";
import ValidationMessages from "../../../../components/common/validation-messages/ValidationMessages";
import ValidationErrors from "../../../../helpers/validation-helper/ValidationErrors";
import Validations from "../../../../helpers/validation-helper/Validations";
import styles from '../../../../styles/ModalWindow.module.scss';
import { IAddManualVotesModalProps } from "./IAddManualVotesModalProps";
import { IAddManualVotesModalState } from "./IAddManualVotesModalState";

class AddManualVotesModal extends Component<IAddManualVotesModalProps, IAddManualVotesModalState> {

  private static dialogIndex = 100;

  constructor(props: IAddManualVotesModalProps) {
    super(props);

    this.state = {
      numberOfVotes: '0',
      dialogId: 'add_manual_votes_dialog_' + (AddManualVotesModal.dialogIndex++),
      validationErrors: {}
    };
  }

  render() {
    return (
      <ReactModal isOpen={this.props.showDialog}
                  id={this.state.dialogId}
                  shouldCloseOnOverlayClick={true}
                  shouldCloseOnEsc={true}
                  className={`${styles.content} shadow p-4`}
                  overlayClassName={`${styles.overlay}`}
                  appElement={document.getElementById('root') as HTMLElement}
      >
        <h3 className={'text-break'}>Add Manual Votes to "{this.props.entryName}"</h3>

        <h5 className='py-2'>Total Number of Votes: {this.props.totalNumberOfVotes}</h5>
        <Form onSubmit={this.onConfirmClicked.bind(this)}>

          <Row>
            <Col xs={6} className='mb-3'>
              <Form.Label>Add Manual Votes</Form.Label>
              <FormControl type='number'
                           name="number_of_votes"
                           value={this.state.numberOfVotes}
                           onFocus={this.onFocus.bind(this)}
                           onChange={this.onVotesChange.bind(this)}
              />
              <ValidationMessages fieldName="NumberOfVotes" errors={this.state.validationErrors}/>
            </Col>
          </Row>

          <div className="d-flex justify-content-end">
            <IconButton buttonType={"submit"} variant="outline-primary" title="Save"
                        styles={{marginRight: '1em'}} disabled={this.props.isLoading}/>

            <IconButton onClick={this.onCancel.bind(this)} variant="outline-secondary" title="Cancel"/>
          </div>
        </Form>
      </ReactModal>
    );
  }

  onConfirmClicked(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let errors = this.validateForm();

    if (Object.keys(errors).length > 0) {
      this.setState({validationErrors: errors});
      return;
    }

    if (this.props.onConfirm) {
      this.props.onConfirm(this.props.entryId ?? 0, +this.state.numberOfVotes);
    }

    let state = {...this.state};
    state.validationErrors = {};
    state.numberOfVotes = "0";
    this.setState(state);
  }

  onVotesChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setAllValidationErrors({});

    this.setState({numberOfVotes: e.target.value});
  }

  onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }

    let state = {...this.state};
    state.validationErrors = {};
    state.numberOfVotes = "0";
    this.setState(state);
  }

  private onFocus() {
    this.setAllValidationErrors({});
  }

  private setAllValidationErrors(validationErrors: ValidationErrors) {
    this.setState({validationErrors});
  }

  private validateForm(): ValidationErrors {
    let errors: ValidationErrors = {};

    if (+this.state.numberOfVotes <= 0) {
      errors = Validations.setErrors({...errors}, 'NumberOfVotes', ["Number of votes should be greater than zero"]);
    }

    if (+this.state.numberOfVotes > 1000) {
      errors = Validations.setErrors({...errors}, 'NumberOfVotes', ["Max votes number that can be added is 1000"]);
    }

    return errors;
  }
}

export default AddManualVotesModal;