import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Form } from "react-bootstrap-v5";
import { KIWANIS_BLUE_COLOR } from "../../../../constants/Constants";
import styles from './CustomDatePickerInput.module.scss';
import { ICustomDatePickerInputProps } from "./ICustomDatePickerInputProps";

class CustomDatePickerInput extends Component<ICustomDatePickerInputProps> {

  render() {
    const {name, value, onClick, onChange} = this.props;

    return (
      <Form.Group>
        <Form.Control type="text" name={name} value={value} onChange={onChange} onClick={onClick}
                      placeholder='Please select date and time'

        />
        <FontAwesomeIcon icon={faCalendarAlt} color={KIWANIS_BLUE_COLOR} size={'lg'} className={styles.datePickerIcon}
                         onClick={onClick}/>
      </Form.Group>
    );
  }
}

export default CustomDatePickerInput;