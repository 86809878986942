import React, { Component } from 'react';
import { Col, Form, Row } from "react-bootstrap-v5";
import FormDescription from "../../../../../../components/common/form-description/FormDescription";
import ValidationMessages from "../../../../../../components/common/validation-messages/ValidationMessages";
import { VerticalSpaceSize } from "../../../../../../components/common/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../../../../components/common/vertical-space/VerticalSpace";
import FieldNameHelper from "../../../../../../helpers/field-name-helper/FieldNameHelper";
import StaffOnly from "../staff-only/StaffOnly";
import { ITextFieldProps } from "./ITextFieldProps";
import { ITextFieldState } from "./ITextFieldState";
import './TextField.scss';

class TextField extends Component<ITextFieldProps, ITextFieldState> {

  constructor(props: ITextFieldProps) {
    super(props);

    this.state = {};
  }

  render() {
    let {field, index, errors} = this.props;
    const textFieldName = FieldNameHelper.getTextFieldName(index);
    const stringValueFieldName = FieldNameHelper.getStringValueFieldName(index);

    return (
      <>
        <Row>
          <Col md={6} lg={5}>
            <Form.Label className='text-break'>{field.name}</Form.Label>
            <Form.Control type="text"
                          name={textFieldName}
                          placeholder={`Please enter ${field.name}`}
                          value={field.value ? field.value : ''}
                          onChange={this.onTextChange.bind(this)}
            />
            <StaffOnly showOnCode={field.showOnCode}/>
            <FormDescription formText={field.description}/>
            <ValidationMessages fieldName={textFieldName} errors={errors}/>
            <ValidationMessages fieldName={stringValueFieldName} errors={errors}/>
          </Col>
        </Row>
        <VerticalSpace size={VerticalSpaceSize.small}/>
      </>
    );
  }

  onTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (this.props.onTextChange) {
      this.props.onTextChange(e.target.value);
    }
  }
}

export default TextField;