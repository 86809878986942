import React, { Component } from 'react';
import ReactModal from 'react-modal';
import IconButton from "../../../../components/common/buttons/icon-button/IconButton";
import { NOTIFICATION_NEEDED } from "../../../../constants/Constants";
import styles from '../../../../styles/ModalWindow.module.scss';
import { IChangeVotingEndTimeNotificationModalProps } from "./IChangeVotingEndTimeNotificationModalProps";
import { IChangeVotingEndTimeNotificationModalState } from "./IChangeVotingEndTimeNotificationModalState";

class ChangeVotingEndTimeNotificationModal extends Component<IChangeVotingEndTimeNotificationModalProps, IChangeVotingEndTimeNotificationModalState> {

  private static dialogIndex = 100;

  constructor(props: IChangeVotingEndTimeNotificationModalProps) {
    super(props);

    this.state = {
      dialogId: 'change_voting_end_time_notification_dialog_' + (ChangeVotingEndTimeNotificationModal.dialogIndex++)
    };

    this.onConfirmClicked = this.onConfirmClicked.bind(this);
  }

  render() {
    return (
      <ReactModal isOpen={this.props.showDialog}
                  id={this.state.dialogId}
                  shouldCloseOnOverlayClick={false}
                  shouldCloseOnEsc={false}
                  className={`${styles.content} shadow p-4`}
                  overlayClassName={`${styles.overlay}`}
                  appElement={document.getElementById('root') as HTMLElement}
      >

        <h2>Voting end time</h2>

        {
          this.props.notificationNeeded === NOTIFICATION_NEEDED.FromPastToFuture &&
          <p>
            You are going to extend voting end time for a contest that has already ended.
            All winners will be reset and new winner calculation will happen on new voting end date/time.
            Are you sure you want to continue?
          </p>
        }

        {
          this.props.notificationNeeded === NOTIFICATION_NEEDED.FromFutureToPast &&
          <p>
            You are going to set voting end time to past.
            Since the contest becomes ended, winners calculation and notification will be performed.
            Are you sure you want to continue?
          </p>
        }

        <div className="d-flex justify-content-end">
          <IconButton onClick={this.onConfirmClicked.bind(this)} variant="outline-primary" title="Confirm"
                      styles={{marginRight: '1em'}}/>

          <IconButton onClick={this.onCancel.bind(this)} variant="outline-secondary" title="Cancel"/>
        </div>
      </ReactModal>
    );
  }

  onConfirmClicked() {
    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
  }

  onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }
}

export default ChangeVotingEndTimeNotificationModal;