import { faCalendarAlt, faTrophy, faUser, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { ChangeEvent, Component } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap-v5";
import DatePicker from "react-datepicker";
import Pagination from "react-responsive-pagination";
import { Redirect } from "react-router-dom";
import ReactToPrint from "react-to-print";
import Api from "../../../../api/Api";
import { IEntriesForPdfRequest } from "../../../../api/api-interfaces/entry/entry-list/IEntriesForPdfRequest";
import { IAddManualVotesRequest } from "../../../../api/api-interfaces/entry/IAddManualVotesRequest";
import { IExportToExcelRequest } from "../../../../api/api-interfaces/entry/entry-list/IExportToExcelRequest";
import { IDeleteEntryRequest } from "../../../../api/api-interfaces/entry/IDeleteEntryRequest";
import { IEntriesRequest } from "../../../../api/api-interfaces/entry/IEntriesRequest";
import withVotingAppAuthorize from "../../../../components/common/authorize/withVotingAppAuthorize";
import IconButton from "../../../../components/common/buttons/icon-button/IconButton";
import Checkbox from "../../../../components/common/checkbox/Checkbox";
import CustomLink from "../../../../components/common/custom-link/CustomLink";
import ImageComponent from "../../../../components/common/image-component/ImageComponent";
import ImageGallery from "../../../../components/common/image-gallery/ImageGallery";
import LoadingBar from "../../../../components/common/loading-bar/LoadingBar";
import SortingTableTitle from "../../../../components/common/sorting-table-title/SortingTableTitle";
import ValidationMessages from "../../../../components/common/validation-messages/ValidationMessages";
import ValidationSummary from "../../../../components/common/validation-summary/ValidationSummary";
import {
  APPROVED_CODES,
  APPROVED_OPTIONS,
  CONTEST_SORTING,
  ENTRY_SORTING,
  KIWANIS_BLUE_COLOR,
  KIWANIS_GOLD_COLOR,
  MOMENT_DATE_FORMAT,
  MONTHS_OPTIONS
} from "../../../../constants/Constants";
import AuthHelper from "../../../../helpers/auth-helper/AuthHelper";
import ValidationErrors from "../../../../helpers/validation-helper/ValidationErrors";
import Validations from "../../../../helpers/validation-helper/Validations";
import RoutingConstants from "../../../../routes/RoutingConstants";
import { pdfPageStyle } from "../../../../styles/PdfStyle";
import DeleteContestModal from "../../delete-contest-modal/DeleteContestModal";
import AddManualVotesModal from "../add-manual-votes-modal/AddManualVotesModal";
import DeleteEntryModal from "../delete-entry-modal/DeleteEntryModal";
import EntriesPdfDocument from "./entries-pdf-document/EntriesPdfDocument";
import styles from './EntryList.module.scss';
import { IEntryListProps } from "./IEntryListProps";
import { IEntryListState } from "./IEntryListState";
import {
  IGetContestAllEntryIdsRequest
} from "../../../../api/api-interfaces/entry/entry-list/IGetContestAllEntryIdsRequest";
import ExportHelper from "../../../../helpers/export-helper/ExportHelper";
import CopyContestModal from "../../copy-contest-modal/CopyContestModal";
import { toast } from "react-toastify";

class EntryList extends Component<IEntryListProps, IEntryListState> {
  private readonly componentRef = React.createRef<HTMLDivElement>();

  constructor(props: IEntryListProps) {
    super(props);

    this.state = {
      contestId: 0,
      contestName: "",
      isContestActive: false,

      entries: null,
      isApproved: APPROVED_CODES.NotSelected,
      createdMonth: '0',
      createdYear: null,
      sorting: ENTRY_SORTING.SubmitTimeDescending,
      page: 1,
      totalPages: 1,

      isAddVotesDialogOpen: false,
      entryIdToAddVotes: undefined,
      entryIndexToAddVotes: undefined,
      entryNameToAddVotes: "",
      entryTotalVotesToAddVotes: 0,

      isDeleteEntryDialogOpen: false,
      entryIdToDelete: undefined,
      entryIndexToDelete: undefined,
      entryNameToDelete: undefined,

      contestIdToCopy: undefined,
      contestNameToCopy: undefined,
      isCopyContestDialogOpen: false,

      contestIdToDelete: undefined,
      contestNameToDelete: undefined,
      isDeleteContestDialogOpen: false,

      fileName: '',
      xlsxBase64: '',

      isOpen: false,
      indexOfImages: 0,
      entryImages: [],

      selectAllEntries: false,
      selectedEntryIds: [],
      entriesForPdf: [],

      isLoading: true,
      validationErrors: null,
      excludeKeys: ['CreatedYear', 'CreatedMonth'],
      redirect: null
    };
  }

  render() {
    let {contestId, contestName, isContestActive, createdYear, entryImages} = this.state;

    if (this.state.redirect !== null) {
      return <Redirect push to={this.state.redirect}/>;
    }

    return (
      <>
        {
          this.state.validationErrors &&
          <Row>
            <Col md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}}>
              <ValidationSummary errors={this.state.validationErrors} excludeKeys={this.state.excludeKeys}/>
            </Col>
          </Row>
        }

        {
          this.state.isLoading ? <LoadingBar/> :
            <>
              <AddManualVotesModal
                isLoading={this.state.isLoading}
                totalNumberOfVotes={this.state.entryTotalVotesToAddVotes}
                entryId={this.state.entryIdToAddVotes}
                entryName={this.state.entryNameToAddVotes}
                showDialog={this.state.isAddVotesDialogOpen}
                onConfirm={this.onConfirmAddVotesClick.bind(this)}
                onCancel={this.onCancelAddVotesClick.bind(this)}
              />

              <DeleteEntryModal
                isLoading={this.state.isLoading}
                showDialog={this.state.isDeleteEntryDialogOpen}
                entryIdToDelete={this.state.entryIdToDelete}
                entryNameToDelete={this.state.entryNameToDelete}
                onConfirm={this.onConfirmDeleteEntryClick.bind(this)}
                onCancel={this.onCancelDeleteEntryClick.bind(this)}
              />

              <CopyContestModal
                isLoading={this.state.isLoading}
                showDialog={this.state.isCopyContestDialogOpen}
                contestIdToCopy={this.state.contestIdToCopy}
                contestNameToCopy={this.state.contestNameToCopy}
                onConfirm={(newContestId) => this.onConfirmCopyContestClick(newContestId)}
                onCancel={this.onCancelCopyContestClick.bind(this)}
              />

              <DeleteContestModal
                isLoading={this.state.isLoading}
                showDialog={this.state.isDeleteContestDialogOpen}
                contestIdToDelete={this.state.contestIdToDelete}
                contestNameToDelete={this.state.contestNameToDelete}
                onConfirm={this.onConfirmDeleteContestClick.bind(this)}
                onCancel={this.onCancelDeleteContestClick.bind(this)}
              />

              <h1 className={"text-break headline"}>{contestName}</h1>

              {
                AuthHelper.isCurrentUserAdmin() &&
                <>
                  <div className="d-flex flex-wrap justify-content-start">
                    <CustomLink to={RoutingConstants.buildCreateEntryUrl(contestId)}>
                      <IconButton size={'sm'} variant={'primary'} title={'Add new entry'} className="m-1"/>
                    </CustomLink>

                    <CustomLink to={RoutingConstants.buildJudgeListUrl(contestId)}>
                      <IconButton size={'sm'} variant={'primary'} title={'Judges'} className="m-1"/>
                    </CustomLink>

                    <CustomLink to={RoutingConstants.buildContestNotificationListUrl(contestId)}>
                      <IconButton size={'sm'} variant={'primary'} title={'Notification list'} className="m-1"/>
                    </CustomLink>

                    {
                      isContestActive
                        ? <IconButton size={'sm'} variant={'primary'} title={'Deactivate contest'}
                                      onClick={() => this.deactivateContest(contestId)} className="m-1"/>
                        : <IconButton size={'sm'} buttonType={'button'} variant={'primary'} title={'Activate contest'}
                                      onClick={() => this.activateContest(contestId)} className="m-1"/>
                    }

                    <CustomLink to={RoutingConstants.buildEditContestUrl(
                      contestId,
                      1,
                      CONTEST_SORTING.Name,
                      RoutingConstants.buildContestDetailsUrl(this.state.contestId))
                    }>
                      <IconButton size={'sm'} variant={'primary'} title={'Edit contest'} className="m-1"/>
                    </CustomLink>

                    <IconButton size={'sm'} variant={'primary'} title={'Copy contest'}
                                onClick={() => this.onCopyContest(contestId, contestName)} className="m-1"/>

                    <IconButton size={'sm'} variant={'danger'} title={'Delete contest'}
                                onClick={() => this.onDeleteContest(contestId, contestName)} className="m-1"/>
                  </div>
                  <hr className='mb-0'/>
                </>
              }

              <h2>Entries</h2>

              <Row className='mb-2'>
                <Col md={4} xxl={3} className='my-1'>
                  <Form.Control as='select' className='form-select form-select-sm'
                                value={this.state.isApproved}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => this.onChangeIsApproved(e)}>
                    {
                      APPROVED_OPTIONS.map((x) => {
                        return <option key={x.code} value={x.code}>{x.name}</option>;
                      })
                    }
                  </Form.Control>
                </Col>

                <Col md={4} xxl={3} className='my-1'>
                  <Form.Control as='select' className='form-select form-select-sm'
                                value={this.state.createdMonth}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => this.onChangeMonths(e)}>
                    {
                      MONTHS_OPTIONS.map((x) => {
                        return <option key={x.code} value={x.code}>{x.name}</option>;
                      })
                    }
                  </Form.Control>
                  <ValidationMessages fieldName="CreatedMonth" errors={this.state.validationErrors ?? {}}/>
                </Col>

                <Col md={4} xxl={3} className='my-1'>
                  <Form.Group controlId="createdYear">
                    <DatePicker
                      selected={createdYear}
                      onChange={(date: Date | null) => this.onChangeYear(date)}
                      showYearPicker
                      dateFormat="yyyy"
                      isClearable={true}
                      placeholderText={'Please select year'}
                      className="form-control form-control-sm"
                    />
                    <ValidationMessages fieldName="CreatedYear" errors={this.state.validationErrors ?? {}}/>
                  </Form.Group>
                </Col>

                <Col xxl={3} className='my-1'>
                  <IconButton buttonType={'button'} variant={'primary'} title={'Filter'} size={"sm"}
                              onClick={() => this.onFilterClick(contestId)} styles={{marginRight: '8px'}}/>

                  <Button className="btn btn-primary btn-sm" onClick={() => this.onExportToExcelClick(contestId)}>
                    Export all to Excel
                  </Button>
                </Col>
              </Row>

              {
                this.state.selectedEntryIds && this.state.selectedEntryIds.length > 0 &&
                <div>
                  <ReactToPrint
                    content={() => this.componentRef.current}
                    documentTitle={this.state.contestName}
                    onBeforeGetContent={this.handleOnBeforeGetContent}
                    removeAfterPrint
                    pageStyle={pdfPageStyle}
                    trigger={() => <Button size={'sm'} className='mb-2'>Print</Button>}
                  />
                  <div ref={this.componentRef}>
                    <EntriesPdfDocument entries={this.state.entriesForPdf}/>
                  </div>
                </div>
              }

              <Table striped bordered responsive className={styles.columnWith}>
                <thead>
                <tr>
                  <th className={styles.checkboxWidth}>
                    <Form.Check type='checkbox' id={'Checkbox_0'}
                                name={'Checkbox_0'}
                                checked={this.state.selectAllEntries}
                                onChange={this.onSelectAllCheckboxChange.bind(this)}
                    />
                  </th>
                  <th style={{width: '160px', minWidth: '120px'}}/>
                  <th className={styles.columnTitleWidth}>
                    <SortingTableTitle name="Title"
                                       sorting={this.state.sorting}
                                       sortBy={ENTRY_SORTING.Title}
                                       sortByDesc={ENTRY_SORTING.TitleDescending}
                                       onSortByClick={this.sortByTitleClick.bind(this)}
                    />
                  </th>
                  <th className={styles.columnDetailsWidth}>
                    <SortingTableTitle name="Submit time"
                                       sorting={this.state.sorting}
                                       sortBy={ENTRY_SORTING.SubmitTime}
                                       sortByDesc={ENTRY_SORTING.SubmitTimeDescending}
                                       onSortByClick={this.sortBySubmitTimeClick.bind(this)}
                    />
                    &nbsp; / Submitter / Approver
                  </th>
                  {
                    AuthHelper.isCurrentUserAdmin() &&
                    <th className={'text-center'}>Add manual votes</th>
                  }
                  <th className={'text-center'}>
                    <SortingTableTitle name="Votes"
                                       sorting={this.state.sorting}
                                       sortBy={ENTRY_SORTING.VotesCount}
                                       sortByDesc={ENTRY_SORTING.VotesCountDescending}
                                       onSortByClick={this.sortByVotesCountClick.bind(this)}
                    />
                  </th>
                  {
                    AuthHelper.isCurrentUserAdmin() &&
                    <th className={'text-center'}/>
                  }
                </tr>
                </thead>
                <tbody>
                {
                  this.state.entries && this.state.entries.length > 0
                    ? this.state.entries.map((entry, index) => {
                      return (
                        <tr key={entry.id} className="text-break">
                          <td className={styles.checkboxWidth}>
                            <Checkbox id={entry.id}
                                      checked={this.checkIfChecked(entry.id)}
                                      onCheckboxChange={() => this.onCheckboxChange(entry.id)}
                            />
                          </td>
                          <td style={{maxWidth: '150px', minWidth: ' 120px'}}>
                            <ImageComponent src={entry.primaryImageUrl}
                                            onClick={() =>
                                              this.setState({isOpen: true, entryImages: entry.allImageUrls})
                                            }
                            />
                          </td>
                          <td className={styles.columnTitleWidth}>
                            {
                              entry.isWinner &&
                              <span><FontAwesomeIcon icon={faTrophy} color={KIWANIS_GOLD_COLOR}/>&nbsp;</span>
                            }
                            <CustomLink to={RoutingConstants.buildEntryDetailsUrl(contestId, entry.id)}
                                        isActive={entry.isApproved}>
                              <span>{entry.title}</span>
                            </CustomLink>
                            {
                              !entry.isApproved && <span> <br/> (not approved)</span>
                            }
                          </td>
                          <td className={styles.columnDetailsWidth}>
                            <span title="Submit time">
                              <FontAwesomeIcon icon={faCalendarAlt} color={KIWANIS_BLUE_COLOR}/>
                              &nbsp; {this.getFormattedDate(entry.submittedOn)} <br/>
                            </span>

                            <span title="Submitter">
                              <FontAwesomeIcon icon={faUser} color={KIWANIS_BLUE_COLOR}/>
                              &nbsp;&nbsp;
                              {
                                entry.submittedBy
                                  ? <span className='text-break'>{entry.submittedBy}</span>
                                  : <span className="text-secondary">(not set)</span>
                              }
                            </span>

                            {
                              entry.isApproved && <span title="Approver">
                                  <br/> <FontAwesomeIcon icon={faUserCheck} color={KIWANIS_BLUE_COLOR}/>
                                &nbsp;
                                {
                                  entry.approvedBy
                                    ? <span className='text-break'>{entry.approvedBy}</span>
                                    : <span className="text-secondary">(not set)</span>
                                }
                              </span>
                            }

                          </td>
                          {
                            AuthHelper.isCurrentUserAdmin() &&
                            <td className='text-center'>
                              <IconButton iconType={'add'} buttonType={'button'} variant={'outline-primary'} size={"sm"}
                                          onClick={() => this.onAddVotes(entry.id, entry.title, entry.votes, index)}/>
                            </td>
                          }
                          <td className='text-center'>
                            <CustomLink to={RoutingConstants.buildEntryVoteListUrl(entry.id)}>
                              {entry.votes}
                            </CustomLink>
                          </td>
                          {
                            AuthHelper.isCurrentUserAdmin() &&
                            <td className='text-center'>
                              <IconButton iconType={'delete'} buttonType={'button'} variant={'outline-danger'} size={"sm"}
                                          onClick={() => this.onDeleteEntry(entry.id, index, entry.title)}/>
                            </td>
                          }
                        </tr>
                      );
                    })
                    : <tr>
                      <td colSpan={6} className={'text-center'}>No contest entries</td>
                    </tr>
                }
                </tbody>
              </Table>

              <ImageGallery
                isOpen={this.state.isOpen}
                indexOfImages={this.state.indexOfImages}
                images={entryImages}
                onCloseRequest={() => this.setState({isOpen: false})}
                onMovePrevRequest={() =>
                  this.setState({indexOfImages: (this.state.indexOfImages + entryImages.length - 1) % entryImages.length})
                }

                onMoveNextRequest={() =>
                  this.setState({indexOfImages: (this.state.indexOfImages + entryImages.length + 1) % entryImages.length})
                }
              />

              {
                this.state.totalPages > 1 &&
                <Row>
                  <Col md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}} className='mt-2'>
                    <Pagination current={this.state.page} total={this.state.totalPages}
                                onPageChange={(page: number) => this.onEntryPageChange(page)}
                    />
                  </Col>
                </Row>
              }
            </>
        }
      </>
    );
  }

  handleOnBeforeGetContent = async () => {
    await this.getEntriesForPdfExport();
  };

  getFormattedDate(submittedOn: string) {
    return submittedOn ? moment(submittedOn).format(MOMENT_DATE_FORMAT) : null;
  }

  async componentDidMount() {
    let contestId = this.props.contestId ?? 0;
    this.setState({contestId: contestId});

    await this.getContestEntryList(contestId, this.state.sorting);
  }

  private async getContestEntryList(contestId: number, sorting: string, page?: number) {
    let isApproved = this.isEntryApproved();
    let createdMonth = this.getCreatedMonth();
    let createdYear = this.getCreatedYear();

    let request: IEntriesRequest = {
      contestId: contestId,
      isApproved: isApproved,
      createdMonth: createdMonth,
      createdYear: createdYear,
      page: page ? page : this.state.page,
      sorting: sorting
    };

    try {
      let response = await Api.getContestEntryList(request);

      this.setState({
        contestName: response.contestName,
        entries: response.pageOfEntries.entries,
        page: response.pageOfEntries.pager.page,
        totalPages: response.pageOfEntries.pager.totalPages,
        isContestActive: response.isContestActive,
        sorting: sorting,
        validationErrors: {},
        isLoading: false
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get list of contest entries from the server', err)
      );
    }
  }

  private isEntryApproved(): boolean | null {
    if (this.state.isApproved === APPROVED_CODES.Approved) {
      return true;
    } else if (this.state.isApproved === APPROVED_CODES.NotApproved) {
      return false;
    } else {
      return null;
    }
  }

  private getCreatedMonth(): number | null {
    if (this.state.createdMonth === '0') {
      return null;
    }
    return parseInt(this.state.createdMonth);
  }

  private getCreatedYear(): number | null {
    if (this.state.createdYear) {
      return moment(this.state.createdYear).year();
    }
    return null;
  }

  private async onConfirmAddVotesClick(entryId: number, numberOfVotes: number) {
    this.setState({isLoading: true});

    let isApproved = this.isEntryApproved();
    let createdMonth = this.getCreatedMonth();
    let createdYear = this.getCreatedYear();

    let request: IAddManualVotesRequest = {
      entryId: entryId,
      isApproved: isApproved,
      createdMonth: createdMonth,
      createdYear: createdYear,
      page: this.state.page,
      sorting: this.state.sorting,
      numberOfVotes: numberOfVotes
    };

    try {
      let response = await Api.addVotesFromEntryList(request);

      this.setState({
        entries: response.pageOfEntries.entries,
        page: response.pageOfEntries.pager.page,
        totalPages: response.pageOfEntries.pager.totalPages,
        isLoading: false,
        validationErrors: {}
      });
    } catch (err) {
      this.setValidationErrors(Validations.buildApiCommunicationErrors('Can\'t add votes', err));

      this.closeAddVotesDialog();
      return;
    }
    this.closeAddVotesDialog();
  }

  private onCancelAddVotesClick() {
    this.closeAddVotesDialog();
  }

  private closeAddVotesDialog() {
    let state = {...this.state};
    state.entryIdToAddVotes = undefined;
    state.entryNameToAddVotes = undefined;
    state.isAddVotesDialogOpen = false;
    this.setState(state);
  }

  private async onAddVotes(entryId: number, entryName: string, votes: number, index: number) {
    let state = {...this.state};
    state.entryNameToAddVotes = entryName;
    state.entryIdToAddVotes = entryId;
    state.entryIndexToAddVotes = index;
    state.entryTotalVotesToAddVotes = votes;
    state.isAddVotesDialogOpen = true;
    this.setState(state);
  }

  private async onDeleteEntry(entryId: number, index: number, entryName: string) {
    let state = {...this.state};
    state.entryNameToDelete = entryName;
    state.entryIdToDelete = entryId;
    state.entryIndexToDelete = index;
    state.isDeleteEntryDialogOpen = true;
    this.setState(state);
  }

  private async onConfirmDeleteEntryClick(entryId: number) {
    this.setState({isLoading: true});

    let isApproved = this.isEntryApproved();
    let createdMonth = this.getCreatedMonth();
    let createdYear = this.getCreatedYear();

    let request: IDeleteEntryRequest = {
      entryId: entryId,
      isApproved: isApproved,
      createdMonth: createdMonth,
      createdYear: createdYear,
      page: this.state.page,
      sorting: this.state.sorting,
    };

    try {
      let response = await Api.deleteEntryFromEntryList(request);

      let state = {...this.state};
      state.entries = response.pageOfEntries.entries;
      state.page = response.pageOfEntries.pager.page;
      state.totalPages = response.pageOfEntries.pager.totalPages;
      state.isLoading = false;
      state.validationErrors = {};
      this.setState(state);
    } catch (err) {
      this.setValidationErrors(Validations.buildApiCommunicationErrors('Can\'t delete entry on the server', err));

      this.closeDeleteEntryDialog();
      return;
    }
    this.closeDeleteEntryDialog();
  }

  private onCancelDeleteEntryClick() {
    this.closeDeleteEntryDialog();
  }

  private closeDeleteEntryDialog() {
    let state = {...this.state};
    state.entryIdToDelete = undefined;
    state.entryNameToDelete = undefined;
    state.isDeleteEntryDialogOpen = false;
    this.setState(state);
  }

  private async onCopyContest(contestId: number, contestName: string) {
    let state = {...this.state};
    state.isCopyContestDialogOpen = true;
    state.contestIdToCopy = contestId;
    state.contestNameToCopy = contestName;
    this.setState(state);
  }

  private async onConfirmCopyContestClick(newContestId: number) {
    let redirectUrl = RoutingConstants.buildEditContestUrl(
      newContestId,
      1,
      CONTEST_SORTING.Name,
      RoutingConstants.buildContestDetailsUrl(newContestId),
      "yes"
    );

    this.setState({redirect: redirectUrl});

    this.closeCopyContestDialog();
  }

  private onCancelCopyContestClick() {
    this.closeCopyContestDialog();
  }

  private closeCopyContestDialog() {
    let state = {...this.state};
    state.contestIdToCopy = undefined;
    state.contestNameToCopy = undefined;
    state.isCopyContestDialogOpen = false;
    this.setState(state);
  }

  private async onDeleteContest(contestId: number, contestName: string) {
    let state = {...this.state};
    state.isDeleteContestDialogOpen = true;
    state.contestIdToDelete = contestId;
    state.contestNameToDelete = contestName;
    this.setState(state);
  }

  private async onConfirmDeleteContestClick() {
    this.setState({isLoading: true});

    let contestId = this.state.contestId;

    try {
      await Api.deleteContestFromEntryList(contestId);

      let message = 'Contest deletion has been queued in background.';
      EntryList.showSuccessToastMessage(message);

      this.setState({redirect: RoutingConstants.buildContestUrl(1, 0)});
    } catch (err) {
      this.setValidationErrors(Validations.buildApiCommunicationErrors('Can\'t delete contest', err));

      this.closeDeleteContestDialog();
      return;
    }
    this.closeDeleteContestDialog();
  }

  private static showSuccessToastMessage(content: string) {
    toast.success(content);
  }

  private onCancelDeleteContestClick() {
    this.closeDeleteContestDialog();
  }

  private closeDeleteContestDialog() {
    let state = {...this.state};
    state.contestIdToDelete = undefined;
    state.contestNameToDelete = undefined;
    state.isDeleteContestDialogOpen = false;
    this.setState(state);
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }

  private async activateContest(contestId: number) {
    try {
      await Api.activateContestFromEntryList(contestId);

      this.setState({isContestActive: true});
    } catch (err) {
      this.setValidationErrors(Validations.buildApiCommunicationErrors('Can\'t activate contest', err));
    }
  }

  private async deactivateContest(contestId: number) {
    try {
      await Api.deactivateContestFromEntryList(contestId);

      this.setState({isContestActive: false});
    } catch (err) {
      this.setValidationErrors(Validations.buildApiCommunicationErrors('Can\'t deactivate contest', err));
    }
  }

  private async onFilterClick(contestId: number) {
    this.setState({isLoading: true, selectedEntryIds: [], selectAllEntries: false});
    await this.getContestEntryList(contestId, this.state.sorting);
  }

  private onExportToExcelClick = async (contestId: number) => {
    this.setState({isLoading: true});

    let isApproved = this.isEntryApproved();
    let createdMonth = this.getCreatedMonth();
    let createdYear = this.getCreatedYear();

    let request: IExportToExcelRequest = {
      contestId: contestId,
      isApproved: isApproved,
      createdMonth: createdMonth,
      createdYear: createdYear,
      sorting: this.state.sorting,
    };

    try {
      let response = await Api.exportEntriesToExcel(request);

      this.setState({fileName: response.fileName, xlsxBase64: response.xlsxBase64, isLoading: false});

      ExportHelper.saveToExcel(response.fileName, response.xlsxBase64);
    } catch (err) {
      this.setValidationErrors(Validations.buildApiCommunicationErrors('Can\'t export entries in xlsx format', err));
    }
  };

  private onChangeYear(date: Date | null) {
    let state = {...this.state};
    state.createdYear = date;
    this.setState(state);
  }

  private onChangeIsApproved(event: React.ChangeEvent<HTMLInputElement>) {
    let state = {...this.state};
    state.isApproved = event.target.value;
    this.setState(state);
  }

  private onChangeMonths(event: React.ChangeEvent<HTMLInputElement>) {
    let state = {...this.state};
    state.createdMonth = event.target.value;
    this.setState(state);
  }

  private async sortByTitleClick() {
    if (this.state.sorting === ENTRY_SORTING.Title) {
      this.setState({isLoading: true});
      await this.getContestEntryList(this.state.contestId, ENTRY_SORTING.TitleDescending);
    } else {
      this.setState({isLoading: true});
      await this.getContestEntryList(this.state.contestId, ENTRY_SORTING.Title);
    }
  }

  private async sortBySubmitTimeClick() {
    if (this.state.sorting === ENTRY_SORTING.SubmitTime) {
      this.setState({isLoading: true});
      await this.getContestEntryList(this.state.contestId, ENTRY_SORTING.SubmitTimeDescending);
    } else {
      this.setState({isLoading: true});
      await this.getContestEntryList(this.state.contestId, ENTRY_SORTING.SubmitTime);
    }
  }

  private async sortByVotesCountClick() {
    if (this.state.sorting === ENTRY_SORTING.VotesCount) {
      this.setState({isLoading: true});
      await this.getContestEntryList(this.state.contestId, ENTRY_SORTING.VotesCountDescending);
    } else {
      this.setState({isLoading: true});
      await this.getContestEntryList(this.state.contestId, ENTRY_SORTING.VotesCount);
    }
  }

  private async onEntryPageChange(page: number) {
    if (this.state.page !== page) {
      this.setState({isLoading: true});
      await this.getContestEntryList(this.state.contestId, this.state.sorting, page);
    }
  }

  private async onSelectAllCheckboxChange() {
    this.setState({isLoading: true});

    if (!this.state.selectAllEntries) {
      await this.getContestAllEntryIds(this.state.contestId);
    } else {
      this.setState({selectAllEntries: false, selectedEntryIds: [], isLoading: false});
    }
  }

  private async getContestAllEntryIds(contestId: number) {
    let isApproved = this.isEntryApproved();
    let createdMonth = this.getCreatedMonth();
    let createdYear = this.getCreatedYear();

    let request: IGetContestAllEntryIdsRequest = {
      contestId: contestId,
      createdYear: createdYear,
      createdMonth: createdMonth,
      isApproved: isApproved
    };

    try {
      let response = await Api.getContestAllEntryIds(request);

      this.setState({
        selectedEntryIds: response.entryIds,
        selectAllEntries: true,
        isLoading: false
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get list of all entry IDs in contest from the server', err)
      );
    }
  }

  private onCheckboxChange(entryId: number) {
    let selectedEntries = [...this.state.selectedEntryIds];

    if (this.checkIfChecked(entryId)) {
      selectedEntries.forEach((x, index) => {
        if (x === entryId) selectedEntries.splice(index, 1);
      });

      this.setState({selectedEntryIds: selectedEntries});
    } else {
      selectedEntries.push(entryId);

      this.setState({selectedEntryIds: selectedEntries});
    }
  }

  private checkIfChecked(entryId: number): boolean {
    return this.state.selectedEntryIds.includes(entryId);
  }

  private async getEntriesForPdfExport() {
    this.setState({isLoading: true});

    let request: IEntriesForPdfRequest = {
      entryIds: this.state.selectedEntryIds,
      sorting: this.state.sorting
    };

    try {
      let response = await Api.getEntriesForPdfExport(request);

      this.setState({
        entriesForPdf: response.entries,
        isLoading: false
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get list of all entry IDs in contest from the server', err)
      );
    }
  }
}

export default withVotingAppAuthorize(EntryList);