import React, { Component } from 'react';
import ReactModal from 'react-modal';
import styles from '../../../styles/ModalWindow.module.scss';
import IconButton from "../../../components/common/buttons/icon-button/IconButton";
import { IAddNewJudgeNotificationModalProps } from "./IAddNewJudgeNotificationModalProps";
import { IAddNewJudgeNotificationModalState } from "./IAddNewJudgeNotificationModalState";

class AddNewJudgeNotificationModal extends Component<IAddNewJudgeNotificationModalProps, IAddNewJudgeNotificationModalState> {

  private static dialogIndex = 100;

  constructor(props: IAddNewJudgeNotificationModalProps) {
    super(props);

    this.state = {
      dialogId: 'add_new_judge_notification_dialog_' + (AddNewJudgeNotificationModal.dialogIndex++)
    };

    this.onConfirmClicked = this.onConfirmClicked.bind(this);
  }

  render() {
    return (
      <ReactModal isOpen={this.props.showDialog}
                  id={this.state.dialogId}
                  shouldCloseOnOverlayClick={false}
                  shouldCloseOnEsc={false}
                  className={`${styles.content} shadow p-4`}
                  overlayClassName={`${styles.overlay}`}
                  appElement={document.getElementById('root') as HTMLElement}
      >

        <h2>User was created</h2>

        <p>
          New user <b>{this.props.dialogEmail}</b> was created and added to judges list
        </p>

        <div className="d-flex justify-content-end">
          <IconButton variant="outline-primary" title="OK" className='px-4' onClick={this.onConfirmClicked}/>
        </div>
      </ReactModal>
    );
  }

  onConfirmClicked() {
    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
  }
}

export default AddNewJudgeNotificationModal;