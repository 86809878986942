import React, { Component } from "react";
import { IToggleButtonProps } from "./IToggleButtonProps";
import styles from "./ToggleButton.module.scss";

class ToggleButton extends Component<IToggleButtonProps> {
  constructor(props: IToggleButtonProps) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  render() {
    return (
      <div className={`${styles.toggleContainer}`} onClick={this.onClickHandler}>
        <div className={`${styles.dialogButton} ${this.props.selected ? "" : styles.disabled}`}>
          {this.props.selected ? "YES" : "NO"}
        </div>
      </div>
    );
  }

  private onClickHandler() {
    if (this.props.toggleSelected !== undefined) {
      this.props.toggleSelected(this.props.selected);
    }
  }
}

export default ToggleButton;