import { CUSTOM_FIELD_CODES } from "../../constants/Constants";

export default class FieldNameHelper {
  public static getTextFieldName(index: number): string {
    return `${CUSTOM_FIELD_CODES.Text}_${index}`;
  }

  public static getTextareaFieldName(index: number): string {
    return `${CUSTOM_FIELD_CODES.TextArea}_${index}`;
  }

  public static getRadioButtonFieldName(index: number): string {
    return `${CUSTOM_FIELD_CODES.RadioButton}_${index}`;
  }

  public static getDropdownFieldName(index: number): string {
    return `${CUSTOM_FIELD_CODES.DropDownList}_${index}`;
  }

  public static getCheckboxFieldName(index: number): string {
    return `${CUSTOM_FIELD_CODES.Checkbox}_${index}`;
  }

  public static getDatePickerFieldName(index: number): string {
    return `${CUSTOM_FIELD_CODES.DatePicker}_${index}`;
  }

// --------------------------------------------------------------- //

  public static getStringValueFieldName(index: number): string {
    return `Fields__${index}___StringValue`;
  }

  public static getBoolValueFieldName(index: number): string {
    return `Fields__${index}___BoolValue`;
  }

  public static getDateTimeValueFieldName(index: number): string {
    return `Fields__${index}___DateTimeValue`;
  }

  // --------------------------------------------------------------- //

  public static getCustomFieldsFieldName(index: number): string {
    return `CustomFields__${index}`;
  }

  public static getNameFieldName(index: number): string {
    return `CustomFields__${index}.Name`;
  }

  public static getTypeCodeFieldName(index: number): string {
    return `CustomFields__${index}.TypeCode`;
  }

  public static getShowOnCodeFieldName(index: number): string {
    return `CustomFields__${index}.ShowOnCode`;
  }

  public static getOptionsFieldName(i: number, j: number): string {
    return `CustomFields__${i}.Options___${j}`;
  }

  // --------------------------------------------------------------- //

  public static getCheckboxName(index: number): string {
    return `Checkbox_${index}`;
  }
}