import React, { FC, ReactElement } from 'react';

const Footer: FC = (): ReactElement => {
  return (
    <footer>
      <small>
        Copyright © {new Date().getFullYear()} KIWANIS INTERNATIONAL. All rights reserved.
      </small>
      <br/>
    </footer>
  );
};

export default Footer;
