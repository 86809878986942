import { faSortAlphaDown, faSortAlphaDownAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ISortingTableTitleProps } from "./ISortingTableTitleProps";
import styles from "./SortingTableTitle.module.scss";

const SortingTableTitle: React.FC<ISortingTableTitleProps> = (props) => {
  const onSortByClick = () => {
    if (props.onSortByClick) {
      props.onSortByClick();
    }
  };

  return (
    <span className={styles.cursorPointer} onClick={onSortByClick}>
      {props.name}

      {
        props.sorting === props.sortBy &&
        <span>&nbsp;<FontAwesomeIcon icon={faSortAlphaDown}/></span>
      }

      {
        props.sorting === props.sortByDesc &&
        <span>&nbsp;<FontAwesomeIcon icon={faSortAlphaDownAlt}/></span>
      }
    </span>
  );
};

export default SortingTableTitle;