import React, { Component } from 'react';
import { Col, Form, Row } from "react-bootstrap-v5";
import FormDescription from "../../../../../../components/common/form-description/FormDescription";
import ValidationMessages from "../../../../../../components/common/validation-messages/ValidationMessages";
import { VerticalSpaceSize } from "../../../../../../components/common/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../../../../components/common/vertical-space/VerticalSpace";
import { DEFAULT } from "../../../../../../constants/Constants";
import FieldNameHelper from "../../../../../../helpers/field-name-helper/FieldNameHelper";
import StaffOnly from "../staff-only/StaffOnly";
import { IRadioButtonFieldProps } from "./IRadioButtonFieldProps";
import { IRadioButtonFieldState } from "./IRadioButtonFieldState";
import './RadioButtonField.scss';

class RadioButtonField extends Component<IRadioButtonFieldProps, IRadioButtonFieldState> {

  constructor(props: IRadioButtonFieldProps) {
    super(props);

    this.state = {};
  }

  render() {
    let {field, index, errors} = this.props;
    const radioButtonFieldName = FieldNameHelper.getRadioButtonFieldName(index);
    const stringValueFieldName = FieldNameHelper.getStringValueFieldName(index);

    return (
      <>
        <Row>
          <Col md={6} lg={5}>
            <Form.Label className='text-break'>{field.name}</Form.Label>
            {
              field.options?.map((option, i) => {
                return (
                  <Form.Check key={'rb_' + field.id + i}
                              type='radio'
                              id={FieldNameHelper.getOptionsFieldName(index, i)}
                              name={radioButtonFieldName}
                              value={field.value}
                              checked={option === field.value}
                              onChange={() => this.onRadioButtonFieldChange(option)}
                              label={option}
                  />
                );
              })
            }
            {
              field && field.value &&
              <Form.Check id={`rb_${index}` + DEFAULT}
                          type='radio'
                          value={DEFAULT}
                          checked={DEFAULT === field.value}
                          onChange={() => this.onRadioButtonFieldChange(DEFAULT)}
                          label={'Not selected'}
              />
            }
            <StaffOnly showOnCode={field.showOnCode}/>
            <FormDescription formText={field.description}/>
            <ValidationMessages fieldName={radioButtonFieldName} errors={errors}/>
            <ValidationMessages fieldName={stringValueFieldName} errors={errors}/>
          </Col>
        </Row>
        <VerticalSpace size={VerticalSpaceSize.small}/>
      </>
    );
  }

  onRadioButtonFieldChange(value: string) {
    if (this.props.onRadioButtonFieldChange) {
      this.props.onRadioButtonFieldChange(value);
    }
  }
}

export default RadioButtonField;