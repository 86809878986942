import React, { Component } from 'react';
import AuthHelper from "../../../helpers/auth-helper/AuthHelper";
import IWithVotingAppAuthorizeProps, { IAuthProps } from "./IWithVotingAppAuthorizeProps";

interface IWithHotelDiaryAuthorizeState {
  auth: IAuthProps
}

export default function withVotingAppAuthorize<TProps extends IWithVotingAppAuthorizeProps>(WrappedComponent: React.ComponentType<TProps>) {

  return class extends Component<Omit<TProps & IWithVotingAppAuthorizeProps, keyof IWithVotingAppAuthorizeProps>, IWithHotelDiaryAuthorizeState> {
    constructor(props: Omit<TProps & IWithVotingAppAuthorizeProps, keyof IWithVotingAppAuthorizeProps>) {
      super(props);

      const authProps: IAuthProps = this.loadAuthProps();

      this.state = {
        auth: authProps
      };

      this.onAuthChange = this.onAuthChange.bind(this);
    }

    private loadAuthProps(): IAuthProps {
      const isAuthenticated = AuthHelper.isTokenSet();

      const authProps: IAuthProps = {
        isAuthenticated: isAuthenticated
      };

      return authProps;
    }

    render() {
      return <WrappedComponent {...this.props as TProps} auth={this.state.auth}/>;
    }

    componentDidMount() {
      AuthHelper.onChange.on(this.onAuthChange);
      this.onAuthChange();
    }

    componentWillUnmount() {
      AuthHelper.onChange.off(this.onAuthChange);
    }

    private onAuthChange() {
      let state = {...this.state};

      state.auth = this.loadAuthProps();

      this.setState(state);
    }
  };
}
