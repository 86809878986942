export default class ExportHelper {
  public static  saveToExcel(fileName: string, xlsxBase64: string) {
    const mimetype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    let link = document.createElement("a");
    link.href = `data:${mimetype};base64,${xlsxBase64}`;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}