import Compress from "compress.js";
import moment from "moment";
import React, { Component } from 'react';
import { Col, Form, Image, Row } from "react-bootstrap-v5";
import { Redirect } from 'react-router-dom';
import { toast } from "react-toastify";
import Api from "../../../../api/Api";
import { EntryPhoto } from "../../../../api/api-interfaces/entry/EntryPhoto";
import { ICustomFieldDefinition } from "../../../../api/api-interfaces/entry/ICustomFieldDefinition";
import { IEntryField } from "../../../../api/api-interfaces/entry/IEntryField";
import { IEntryFieldInfo } from "../../../../api/api-interfaces/entry/IEntryFieldInfo";
import { IEntryPhoto } from "../../../../api/api-interfaces/entry/IEntryPhoto";
import { ISaveRequest } from "../../../../api/api-interfaces/entry/ISaveRequest";
import withVotingAppAuthorize from "../../../../components/common/authorize/withVotingAppAuthorize";
import IconButton from "../../../../components/common/buttons/icon-button/IconButton";
import ImageUpload from "../../../../components/common/drop-zone/ImageUpload";
import FormDescription from "../../../../components/common/form-description/FormDescription";
import LoadingBar from "../../../../components/common/loading-bar/LoadingBar";
import RequiredField from "../../../../components/common/required-field/RequiredField";
import ValidationMessages from "../../../../components/common/validation-messages/ValidationMessages";
import ValidationSummary from "../../../../components/common/validation-summary/ValidationSummary";
import { VerticalSpaceSize } from "../../../../components/common/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../../components/common/vertical-space/VerticalSpace";
import {
  CHECK_ERROR_MESSAGE,
  CUSTOM_FIELD_CODES,
  DATE_TIME_ISO_FORMAT,
  DEFAULT
} from "../../../../constants/Constants";
import FieldNameHelper from "../../../../helpers/field-name-helper/FieldNameHelper";
import { scrollIntoViewHelper } from "../../../../helpers/scroll-into-view-helper/scrollIntoViewHelper";
import ValidationErrors from "../../../../helpers/validation-helper/ValidationErrors";
import Validations from "../../../../helpers/validation-helper/Validations";
import RoutingConstants from "../../../../routes/RoutingConstants";
import imageStyles from '../../../../styles/ImageBigStyles.module.scss';
import './AddOrEditEntry.scss';
import CheckboxField from "./custom-fields/checkbox/CheckboxField";
import DateTimePickerField from "./custom-fields/datepicker/DateTimePickerField";
import DropdownField from "./custom-fields/dropdown/DropdownField";
import RadioButtonField from "./custom-fields/radio-button/RadioButtonField";
import TextField from "./custom-fields/text/TextField";
import TextareaField from "./custom-fields/textarea/TextareaField";
import { IAddOrEditEntryProps } from "./IAddOrEditEntryProps";
import { IAddOrEditEntryState } from "./IAddOrEditEntryState";

class AddOrEditEntry extends Component<IAddOrEditEntryProps, IAddOrEditEntryState> {

  constructor(props: IAddOrEditEntryProps) {
    super(props);

    this.state = {
      contestId: null,
      entryId: null,
      title: '',
      photos: [],
      description: '',
      customFields: [],
      excludeKeys: ['Title', 'Description', 'Photos'],
      redirect: null,
      validationErrors: null,
      isLoading: true,
      isImageLoading: false
    };
  }

  render() {
    const errors = this.state.validationErrors ?? {};

    if (this.state.redirect !== null) {
      return <Redirect push to={this.state.redirect}/>;
    }

    return (
      <>
        {
          this.state.validationErrors &&
          <Row>
            <Col md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}}>
              <ValidationSummary errors={this.state.validationErrors} excludeKeys={this.state.excludeKeys}/>
            </Col>
          </Row>
        }

        {
          this.state.isLoading ? <LoadingBar/> :
            <>
              <h1 className={"text-break headline"}>{this.state.entryId ? "Edit entry" : "Create entry"}</h1>
              <VerticalSpace size={VerticalSpaceSize.small}/>

              <Form>
                <Row>
                  <Col md={6} lg={5}>
                    <Form.Label>Title<RequiredField/></Form.Label>
                    <Form.Control name="Title" type="text" placeholder="Enter title"
                                  value={this.state.title}
                                  onChange={this.onTitleChange.bind(this)}
                    />
                    <ValidationMessages fieldName="Title" errors={errors}/>
                  </Col>
                </Row>

                <VerticalSpace size={VerticalSpaceSize.small}/>

                <Row>
                  <Col md={6} lg={5}>
                    <Form.Label>Images<RequiredField/></Form.Label>
                    <FormDescription formText={'Only JPEG images are allowed. ' +
                        'Please notice that the best aspect ratio for uploaded images is 16x9'}
                    />

                    <ImageUpload photos={this.state.photos}
                                 onDrop={(acceptedFiles: File[]) => this.onDrop(acceptedFiles)}
                                 onRemoveFile={(index) => this.onRemoveFile(index)}
                                 onSelectMainPhoto={(index) => this.onSelectMainPhoto(index)}
                                 isImageLoading={this.state.isImageLoading}
                    />

                    <ValidationMessages fieldName="Photos" errors={errors}/>
                  </Col>

                  {
                    this.state.photos.map((x, j) => {
                      return x.isDefault &&
                        <Col md={4} key={j}>
                          <Form.Label>Main photo</Form.Label><br/>
                          <div className={imageStyles.vaContainerWithoutOverlay}>
                            <Image src={x.url ? x.url : URL.createObjectURL(x.imageFile)} alt={'default entry img'}
                                   className={imageStyles.vaImage} fluid thumbnail/>
                          </div>
                        </Col>;
                    })
                  }
                </Row>
                <VerticalSpace size={VerticalSpaceSize.small}/>

                <Row>
                  <Col md={6} lg={5}>
                    <Form.Label>Description</Form.Label>
                    <Form.Control name="Description" type="text"
                                  placeholder="Enter description"
                                  value={this.state.description}
                                  onChange={this.onDescriptionChange.bind(this)}
                    />
                    <ValidationMessages fieldName="Description" errors={errors}/>
                  </Col>
                </Row>
                <VerticalSpace size={VerticalSpaceSize.small}/>

                {
                  this.state.customFields.map((field, index) => {
                    switch (field.typeCode) {
                      case CUSTOM_FIELD_CODES.Text:
                        return (
                          <TextField key={field.id} index={index} field={field} errors={errors}
                                     onTextChange={value => this.onTextFieldChange(value, index)}
                          />
                        );
                      case CUSTOM_FIELD_CODES.TextArea:
                        return (
                          <TextareaField key={field.id} index={index} field={field} errors={errors}
                                         onTextareaChange={value => this.onTextareaFieldChange(value, index)}
                          />
                        );
                      case CUSTOM_FIELD_CODES.RadioButton:
                        return (
                          <RadioButtonField key={field.id} index={index} field={field} errors={errors}
                                            onRadioButtonFieldChange={value => this.onRadioButtonFieldChange(value, index)}
                          />
                        );
                      case CUSTOM_FIELD_CODES.DropDownList:
                        return (
                          <DropdownField key={field.id} index={index} field={field} errors={errors}
                                         onDropdownFieldChange={value => this.onDropdownFieldChange(value, index)}
                          />
                        );
                      case CUSTOM_FIELD_CODES.Checkbox:
                        return (
                          <CheckboxField key={field.id} index={index} field={field} errors={errors}
                                         onCheckboxFieldChange={value => this.onCheckboxFieldChange(value, index)}
                          />
                        );
                      case CUSTOM_FIELD_CODES.DatePicker:
                        return (
                          <DateTimePickerField key={field.id} index={index} field={field} errors={errors}
                                               onDateClick={date => this.onDateFieldChange(date, index)}
                          />
                        );
                      default:
                        return (
                          <div>
                            <span>Invalid field</span>
                          </div>
                        );
                    }
                  })
                }

                <IconButton onClick={this.onSaveEntry.bind(this)} title="Save" variant={"outline-primary"}
                            styles={{marginRight: "1em"}}/>

                <IconButton onClick={this.onCancel.bind(this)} title="Cancel" variant="outline-secondary"/>
              </Form>
            </>
        }
      </>
    );
  }

  async componentDidMount() {
    let contestId = this.props.contestId;
    let entryId = this.props.entryId;
    this.setState({contestId: contestId, entryId: entryId});

    if (entryId) {
      await this.getDetailsForEditing(entryId);
    } else {
      await this.getDetailsForCreation(contestId ?? 0);
    }
  }

  private async getDetailsForCreation(contestId: number) {
    try {
      let response = await Api.getDetailsForCreation(contestId);

      let state = {...this.state};
      state.customFields = response.customFields;

      let excludeKeys = this.addFieldsForCreationToExcludeKeys(response.customFields);
      state.excludeKeys = [...state.excludeKeys, ...excludeKeys];

      state.isLoading = false;
      this.setState(state);
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get details for creation', err)
      );
    }
  }

  private async getDetailsForEditing(entryId: number) {
    try {
      let response = await Api.getDetailsForEditing(entryId);

      let state = {...this.state};

      let excludeKeys = this.addFieldForEditingToExcludeKeys(response.fields);
      state.excludeKeys = [...state.excludeKeys, ...excludeKeys];

      state.title = response.title;
      state.description = response.description;
      state.customFields = this.convertIEntryFieldToICustomFieldDefinition(response.fields);
      state.photos = response.photos;

      state.isLoading = false;
      this.setState(state);
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get details for editing', err)
      );
    }
  }

  private onTitleChange(e: React.ChangeEvent<HTMLInputElement>) {
    Validations.deleteErrors(this.state.validationErrors ?? {}, 'Title');
    this.setState({title: e.target.value});
  }

  private onDescriptionChange(e: React.ChangeEvent<HTMLInputElement>) {
    Validations.deleteErrors(this.state.validationErrors ?? {}, 'Description');
    this.setState({description: e.target.value});
  }

  private onTextFieldChange(value: string, index: number) {
    let state = {...this.state};
    let errors = Validations.deleteErrors(this.state.validationErrors ?? {}, FieldNameHelper.getTextFieldName(index));
    state.customFields[index].value = value;
    state.validationErrors = errors;
    this.setState(state);
  }

  private onTextareaFieldChange(value: string, index: number) {
    let state = {...this.state};
    let errors = Validations.deleteErrors(this.state.validationErrors ?? {}, FieldNameHelper.getTextareaFieldName(index));
    state.customFields[index].value = value;
    state.validationErrors = errors;
    this.setState(state);
  }

  private onRadioButtonFieldChange(value: string, index: number) {
    let state = {...this.state};
    let errors = Validations.deleteErrors(this.state.validationErrors ?? {}, FieldNameHelper.getRadioButtonFieldName(index));
    state.customFields[index].value = value;
    state.validationErrors = errors;
    this.setState(state);
  }

  private onDropdownFieldChange(value: string, index: number) {
    let state = {...this.state};
    let errors = Validations.deleteErrors(this.state.validationErrors ?? {}, FieldNameHelper.getDropdownFieldName(index));
    state.customFields[index].value = value === DEFAULT ? null : value;
    state.validationErrors = errors;
    this.setState(state);
  }

  private onCheckboxFieldChange(value: boolean, index: number) {
    let state = {...this.state};
    let errors = Validations.deleteErrors(this.state.validationErrors ?? {}, FieldNameHelper.getCheckboxFieldName(index));
    state.customFields[index].value = value;
    state.validationErrors = errors;
    this.setState(state);
  }

  private onDateFieldChange(date: Date, index: number) {
    let state = {...this.state};
    let errors = Validations.deleteErrors(this.state.validationErrors ?? {}, FieldNameHelper.getDatePickerFieldName(index));
    state.customFields[index].value = date;
    state.validationErrors = errors;
    this.setState(state);
  }

  private async onSaveEntry() {
    let errors = this.validateForm();

    if (Object.keys(errors).length > 0) {
      this.setState({validationErrors: errors});
      AddOrEditEntry.showErrorToastMessage();
      scrollIntoViewHelper(errors);
      return;
    }

    this.setState({isLoading: true});

    let contestId: number | null = this.state.entryId ? null : this.state.contestId ?? 0;
    let fields: IEntryFieldInfo[] = this.convertICustomFieldDefinitionToIEntryFieldInfo(this.state.customFields);
    let photos: IEntryPhoto[] = this.convertToIEntryPhoto(this.state.photos);

    let request: ISaveRequest = {
      contestId: contestId,
      contestEntryId: this.state.entryId,
      title: this.state.title,
      description: this.state.description,
      fields: fields,
      photos: photos
    };

    try {
      let response = await Api.saveContestEntry(request);

      let state = {...this.state};
      state.redirect = RoutingConstants.buildEntryDetailsUrl(this.state.contestId ?? 0, response.entryId);
      this.setState(state);
    } catch (err) {
      this.setValidationErrors(Validations.buildApiCommunicationErrors('Can\'t create or update entry', err));
    }
  }

  private onCancel() {
    let state = {...this.state};
    state.redirect = this.state.entryId
      ? RoutingConstants.buildEntryDetailsUrl(this.state.contestId ?? 0, this.state.entryId)
      : RoutingConstants.buildContestDetailsUrl(this.state.contestId ?? 0);
    this.setState(state);
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }

  private onDrop(acceptedFiles: File[]) {
    this.setState({isImageLoading: true});

    const compress = new Compress();

    const compressOptions = {
      size: 2, // the max size in MB, defaults to 2MB
      quality: .75, // the quality of the image, max is 1,
      maxWidth: 1920, // the max width of the output image, defaults to 1920px
      maxHeight: 1920, // the max height of the output image, defaults to 1920px
      resize: true, // defaults to true, set false if you do not want to resize the image width and height
    };

    compress.compress(acceptedFiles, compressOptions).then((data) => {
      // returns an array of compressed images

      let state = {...this.state};
      let photos: EntryPhoto[] = [];

      data.forEach(img => {
        const imgName = img.alt;
        const base64str = img.data;
        const imgExt = img.ext;
        const file = Compress.convertBase64ToFile(base64str, imgExt);

        photos.push({
          isDefault: false,
          newPhoto: {
            fileName: imgName,
            contentBase64: base64str,
            mimeType: imgExt
          },
          imageFile: file
        });
      });

      state.photos = [...state.photos, ...photos];
      state.validationErrors = Validations.deleteErrors(this.state.validationErrors ?? {}, 'Photos');


      if (state.photos.length >= 1) {
        let isDefaultExists = state.photos.find(u => u.isDefault) !== undefined;

        if (!isDefaultExists) {
          state.photos[0].isDefault = true;
        }
      }

      state.isImageLoading = false;
      this.setState(state);
    });
  }

  private onRemoveFile(index: number) {
    let state = {...this.state};
    if (index !== -1) {
      state.photos.splice(index, 1);
    }

    this.setState(state);
  }

  private onSelectMainPhoto(index: number) {
    let state = {...this.state};

    state.photos.forEach((x, i) => {
      x.isDefault = i === index;
    });

    state.validationErrors = Validations.deleteErrors(this.state.validationErrors ?? {}, 'Photos');

    this.setState(state);
  }

  private validateForm(): ValidationErrors {
    let errors: ValidationErrors = {};

    if (!this.state.title || this.state.title.trim().length < 1) {
      errors = Validations.setErrors({...errors}, 'Title', ['Title is required']);
    }

    if (this.state.title && this.state.title.length > 500) {
      errors = Validations.setErrors({...errors}, 'Title', ['Title can\'t be longer than 500 characters']);
    }

    if (this.state.description && this.state.description.length > 500) {
      errors = Validations.setErrors({...errors}, 'Description', ['Description can\'t be longer than 500 characters']);
    }

    if (!this.state.photos || this.state.photos.length < 1) {
      errors = Validations.setErrors({...errors}, 'Photos', ['At least one photo should be selected as default']);
    }

    if (this.state.photos && this.state.photos.length >= 1) {

      let wasDefaultPhotoFound = false;

      this.state.photos.forEach(x => {
        if (x.isDefault) {
          wasDefaultPhotoFound = true;
        }
      });

      if (!wasDefaultPhotoFound) {
        errors = Validations.setErrors({...errors}, 'Photos', ['At least one photo should be selected as default']);
      }
    }

    return errors;
  }

  private static showErrorToastMessage() {
    toast.error(CHECK_ERROR_MESSAGE);
  }

  convertICustomFieldDefinitionToIEntryFieldInfo(customFields: ICustomFieldDefinition[]) {
    if (customFields.length === 0) {
      return [];
    }

    let fields: IEntryFieldInfo[] = [];

    customFields.forEach(x => {
      switch (x.typeCode) {
        case CUSTOM_FIELD_CODES.Text:
        case CUSTOM_FIELD_CODES.TextArea:
        case CUSTOM_FIELD_CODES.RadioButton:
          if (!x.isRequired) {
            fields.push({
              contestCustomFieldId: x.id,
              stringValue: x.value === DEFAULT ? null : x.value
            });
            break;
          } else {
            fields.push({
              contestCustomFieldId: x.id,
              stringValue: x.value === DEFAULT ? null : x.value
            });
            break;
          }
        case CUSTOM_FIELD_CODES.DropDownList:
          fields.push({
            contestCustomFieldId: x.id,
            stringValue: x.value
          });
          break;
        case CUSTOM_FIELD_CODES.Checkbox:
          fields.push({
            contestCustomFieldId: x.id,
            boolValue: x.value
          });
          break;
        case CUSTOM_FIELD_CODES.DatePicker:
          fields.push({
            contestCustomFieldId: x.id,
            dateTimeValue: x.value ? moment(x.value).format(DATE_TIME_ISO_FORMAT) : null
          });
          break;
        default:
          break;
      }
    });

    return fields;
  }

  convertIEntryFieldToICustomFieldDefinition(customFields: IEntryField[]) {
    if (customFields.length === 0) {
      return [];
    }

    let fields: ICustomFieldDefinition[] = [];

    customFields.forEach(x => {
      switch (x.fieldTypeCode) {
        case CUSTOM_FIELD_CODES.Text:
        case CUSTOM_FIELD_CODES.TextArea:
        case CUSTOM_FIELD_CODES.RadioButton:
        case CUSTOM_FIELD_CODES.DropDownList:
          fields.push({
            id: x.contestCustomFieldId,
            name: x.name,
            typeCode: x.fieldTypeCode,
            showOnCode: x.showOnCode,
            description: x.description,
            isRequired: x.isRequired,
            options: x.options,
            value: x.stringValue ? x.stringValue : ''
          });
          break;
        case CUSTOM_FIELD_CODES.Checkbox:
          fields.push({
            id: x.contestCustomFieldId,
            name: x.name,
            typeCode: x.fieldTypeCode,
            showOnCode: x.showOnCode,
            description: x.description,
            isRequired: x.isRequired,
            options: x.options,
            value: x.boolValue
          });
          break;
        case CUSTOM_FIELD_CODES.DatePicker:
          fields.push({
            id: x.contestCustomFieldId,
            name: x.name,
            typeCode: x.fieldTypeCode,
            showOnCode: x.showOnCode,
            description: x.description,
            isRequired: x.isRequired,
            options: x.options,
            value: x.dateTimeValue
          });
          break;
        default:
          break;
      }
    });

    return fields;
  }

  convertToIEntryPhoto(photos: EntryPhoto[]) {
    if (photos.length === 0) {
      return [];
    }

    let entryPhotos: IEntryPhoto[] = [];

    photos.forEach(x => {
      entryPhotos.push({
        oldPhoto: x.id ? {id: x.id} : null,
        isDefault: x.isDefault,
        newPhoto: x.newPhoto,
      });
    });

    return entryPhotos;
  }

  addFieldsForCreationToExcludeKeys(customFields: ICustomFieldDefinition[]) {
    if (customFields.length === 0) {
      return [];
    }

    let excludeKeys: string[] = [];

    customFields.forEach((x, index) => {
      switch (x.typeCode) {
        case CUSTOM_FIELD_CODES.Text:
        case CUSTOM_FIELD_CODES.TextArea:
        case CUSTOM_FIELD_CODES.RadioButton:
        case CUSTOM_FIELD_CODES.DropDownList:
          excludeKeys.push(FieldNameHelper.getStringValueFieldName(index));
          break;
        case CUSTOM_FIELD_CODES.Checkbox:
          excludeKeys.push(FieldNameHelper.getBoolValueFieldName(index));
          break;
        case CUSTOM_FIELD_CODES.DatePicker:
          excludeKeys.push(FieldNameHelper.getDateTimeValueFieldName(index));
          break;
        default:
          break;
      }
    });

    return excludeKeys;
  }

  addFieldForEditingToExcludeKeys(customFields: IEntryField[]) {
    if (customFields.length === 0) {
      return [];
    }

    let excludeKeys: string[] = [];

    customFields.forEach((x, index) => {
      switch (x.fieldTypeCode) {
        case CUSTOM_FIELD_CODES.Text:
        case CUSTOM_FIELD_CODES.TextArea:
        case CUSTOM_FIELD_CODES.RadioButton:
        case CUSTOM_FIELD_CODES.DropDownList:
          excludeKeys.push(FieldNameHelper.getStringValueFieldName(index));
          break;
        case CUSTOM_FIELD_CODES.Checkbox:
          excludeKeys.push(FieldNameHelper.getBoolValueFieldName(index));
          break;
        case CUSTOM_FIELD_CODES.DatePicker:
          excludeKeys.push(FieldNameHelper.getDateTimeValueFieldName(index));
          break;
        default:
          break;
      }
    });

    return excludeKeys;
  }
}

export default withVotingAppAuthorize(AddOrEditEntry);