import moment from "moment";
import React, { Component } from "react";
import { Col, Form, Row } from "react-bootstrap-v5";
import { Redirect } from "react-router-dom";
import Api from "../../../api/Api";
import { ISaveUserRequest } from "../../../api/api-interfaces/users/ISaveUserRequest";
import withVotingAppAuthorize from "../../../components/common/authorize/withVotingAppAuthorize";
import IconButton from "../../../components/common/buttons/icon-button/IconButton";
import ToggleButton from "../../../components/common/buttons/toggle-button/ToggleButton";
import LoadingBar from "../../../components/common/loading-bar/LoadingBar";
import RequiredField from "../../../components/common/required-field/RequiredField";
import ValidationMessages from "../../../components/common/validation-messages/ValidationMessages";
import ValidationSummary from "../../../components/common/validation-summary/ValidationSummary";
import { VerticalSpaceSize } from "../../../components/common/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../components/common/vertical-space/VerticalSpace";
import { EMAIL_REGEX, USER_SORTING } from "../../../constants/Constants";
import AuthHelper from "../../../helpers/auth-helper/AuthHelper";
import { scrollIntoViewHelper } from "../../../helpers/scroll-into-view-helper/scrollIntoViewHelper";
import ValidationErrors from "../../../helpers/validation-helper/ValidationErrors";
import Validations from "../../../helpers/validation-helper/Validations";
import RoutingConstants from "../../../routes/RoutingConstants";
import { IAddOrEditUserProps } from "./IAddOrEditUserProps";
import { IAddOrEditUserState } from "./IAddOrEditUserState";

class AddOrEditUser extends Component<IAddOrEditUserProps, IAddOrEditUserState> {

  constructor(props: IAddOrEditUserProps) {
    super(props);

    this.state = {
      userId: null,
      pageToRedirect: 1,
      sorting: USER_SORTING.LastName,
      firstName: "",
      lastName: "",
      email: "",
      isAdmin: false,
      redirect: null,
      isLoading: true,
      excludeKeys: ["FirstName", "LastName", "Email"],
      validationErrors: {},
      redirectUrl: null
    };

    this.onFirstNameChange = this.onFirstNameChange.bind(this);
    this.onLastNameChange = this.onLastNameChange.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onSaveUser = this.onSaveUser.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  render() {
    if (this.state.redirect !== null) {
      return <Redirect push to={this.state.redirect}/>;
    }

    return (
      <>
        {
          this.state.validationErrors &&
          <Row>
            <Col md={{span: 6, offset: 3}}>
              <ValidationSummary errors={this.state.validationErrors} excludeKeys={this.state.excludeKeys}/>
            </Col>
          </Row>
        }

        {
          this.state.isLoading ? <LoadingBar/> :
            <>
              <h1 className='headline'>{!this.state.userId ? 'Create user' : 'Edit user'}</h1>
              <VerticalSpace size={VerticalSpaceSize.small}/>

              <Form>
                <Row>
                  <Col md={8} lg={6} xl={4}>
                    <Form.Label>First name<RequiredField/></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Please enter first name"
                      value={this.state.firstName ?? ''}
                      onChange={this.onFirstNameChange}
                    />
                    <ValidationMessages fieldName="FirstName" errors={this.state.validationErrors}/>
                  </Col>
                </Row>
                <VerticalSpace size={VerticalSpaceSize.small}/>

                <Row>
                  <Col md={8} lg={6} xl={4}>
                    <Form.Label>Last name<RequiredField/></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Please enter last name"
                      value={this.state.lastName ?? ''}
                      onChange={this.onLastNameChange}
                    />
                    <ValidationMessages fieldName="LastName" errors={this.state.validationErrors}/>
                  </Col>
                </Row>
                <VerticalSpace size={VerticalSpaceSize.small}/>

                <Row>
                  <Col md={8} lg={6} xl={4}>
                    <Form.Label>Email<RequiredField/></Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Please enter email"
                      value={this.state.email}
                      onChange={this.onEmailChange}
                    />
                    <ValidationMessages fieldName="Email" errors={this.state.validationErrors}/>
                  </Col>
                </Row>
                <VerticalSpace size={VerticalSpaceSize.small}/>

                <Row className='align-items-center'>
                  <Col xs={6} md={5} lg={3} xl={3}>
                    <strong className='text-break'>Administrator:</strong>
                  </Col>
                  <Col>
                    <ToggleButton selected={this.state.isAdmin}
                                  toggleSelected={(isSelected) => this.onUserAdminToggle(isSelected)}
                    />
                  </Col>
                </Row>
                <VerticalSpace size={VerticalSpaceSize.normal}/>

                <IconButton onClick={this.onSaveUser} title="Save" variant={"outline-primary"}
                            styles={{marginRight: "1em"}}/>
                <IconButton onClick={this.onCancel} title="Cancel" variant={"outline-secondary"}/>
              </Form>
            </>
        }
      </>
    );

  }

  async componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const page = params.get('page');
    const pageToRedirect = !page ? 1 : parseInt(page);

    const sortingParam = params.get('sorting');
    const sorting = sortingParam ? sortingParam : this.state.sorting;

    const url = params.get('redirectUrl');
    const redirectUrl = url ? url : null;

    let userId: number | null;
    if (this.props.userId) {
      userId = parseInt(this.props.userId);
    } else {
      userId = null;
    }
    this.setState({
      userId: userId,
      pageToRedirect: pageToRedirect,
      sorting: sorting,
      redirectUrl: redirectUrl
    });

    await this.getUserDetailsForSaving(userId);
  }

  private async getUserDetailsForSaving(userId: null | number) {
    if (userId) {
      try {
        let response = await Api.getUserDetailsForSaving(userId);

        let state = {...this.state};
        state.firstName = response.firstName;
        state.lastName = response.lastName;
        state.email = response.email;
        state.isAdmin = response.isAdmin;
        state.isLoading = false;
        this.setState(state);
      } catch (err) {
        this.setValidationErrors(
          Validations.buildApiCommunicationErrors('Can\'t get user details for saving', err)
        );
      }
    } else {
      this.setState({isLoading: false});
    }
  }

  async onSaveUser() {
    let errors = this.validateForm();
    if (Object.keys(errors).length > 0) {
      this.setState({validationErrors: errors});
      scrollIntoViewHelper(errors);
      return;
    }

    this.setState({isLoading: true});

    try {
      let request: ISaveUserRequest = {
        id: this.state.userId,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        isAdmin: this.state.isAdmin
      };

      let response = await Api.saveUser(request);

      let currentUserFullName = response.currentUserFullName;
      let expires = moment(response.currentUserTokenValidToUtc);

      AuthHelper.setUserFullName(currentUserFullName, expires.toDate());

      let state = {...this.state};
      state.isLoading = false;
      state.redirect = this.getRedirect(response.userId);
      this.setState(state);
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t save user on the server', err)
      );
    }
  }

  private getRedirect(userId: number) {
    if (this.state.redirectUrl) {
      return this.state.redirectUrl;
    } else {
      return this.state.userId
        ? RoutingConstants.buildUsersUrl(this.state.pageToRedirect, this.state.userId, this.state.sorting)
        : RoutingConstants.buildUsersUrl(this.state.pageToRedirect, userId, this.state.sorting);
    }
  }

  private onCancel() {
    let state = {...this.state};
    state.redirect = this.getRedirect(0);
    this.setState(state);
  }

  private onFirstNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    let errors = Validations.deleteErrors(this.state.validationErrors, 'FirstName');
    this.setState({firstName: e.target.value, validationErrors: errors});
  }

  private onLastNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    let errors = Validations.deleteErrors(this.state.validationErrors, 'LastName');
    this.setState({lastName: e.target.value, validationErrors: errors});
  }

  private onEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    let errors = Validations.deleteErrors(this.state.validationErrors, 'Email');
    this.setState({email: e.target.value, validationErrors: errors});
  }

  private onUserAdminToggle(isToggled: boolean) {
    this.setState({isAdmin: !isToggled});
  }

  private validateForm(): ValidationErrors {
    let errors: ValidationErrors = {};

    if (!this.state.firstName || this.state.firstName.trim().length < 1) {
      errors = Validations.setErrors({...errors}, 'FirstName', ['First name is required.']);
    }

    if (!this.state.lastName || this.state.lastName.trim().length < 1) {
      errors = Validations.setErrors({...errors}, 'LastName', ['Last name is required.']);
    }

    if (!EMAIL_REGEX.test(this.state.email)) {
      errors = Validations.setErrors({...errors}, 'Email', ['Email is invalid.']);
    }

    if (!this.state.email || this.state.email.trim().length < 1) {
      errors = Validations.setErrors({...errors}, 'Email', ['Email is required.']);
    }

    return errors;
  }

  private setValidationErrors(errors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = errors;
    state.isLoading = false;
    this.setState(state);
  }
}

export default withVotingAppAuthorize(AddOrEditUser);