import SetOptions from "suneditor-react/dist/types/SetOptions";
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  image,
  lineHeight,
  link,
  list,
  paragraphStyle,
  table,
  template,
  textStyle
} from "suneditor/src/plugins";

export const sunEditorOptions: SetOptions = {
  plugins: [
    align,
    font,
    fontColor,
    fontSize,
    formatBlock,
    hiliteColor,
    horizontalRule,
    lineHeight,
    list,
    paragraphStyle,
    table,
    template,
    textStyle,
    image,
    link
  ],
  defaultTag: 'p',
  mode: 'classic',
  width: '100%',
  height: 'auto',
  minHeight: "30vh",
  placeholder: "Enter your text here...",
  imageHeightShow: true,
  imageResizing: true,
  imageWidth: 'auto',
  imageHeight: 'auto',
  charCounter: true,
  buttonList: [
    ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
    '/', // Line break
    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
    ['link', 'image', 'showBlocks', 'codeView', 'preview', 'print']
  ],
  formats: ['p', 'div', 'blockquote', 'pre', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
  font: [
    "Arial",
    "Calibri",
    "Comic Sans",
    "Courier",
    "Garamond",
    "Georgia",
    "Impact",
    "Lucida Console",
    "Palatino Linotype",
    "Segoe UI",
    "Tahoma",
    "Times New Roman",
    "Trebuchet MS"
  ]
};
